import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalWindow } from '@ng-bootstrap/ng-bootstrap/modal/modal-window';
import { Classifier, GeomStyle, LayerField } from '@pgis/shared/models';
import { ValidationService, ClassifiersService, LocalizedToastrService } from '@pgis/core/services';
import * as _ from 'lodash';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './classifier-modal.component.html',
  styleUrls: ['./classifier-modal.component.scss']
})

export class ClassifierModalComponent implements OnInit {

  modalWindow: NgbModalWindow;

  classifier: Classifier = new Classifier();
  duplicateClassifier: boolean;
  duplicateClassifierFrom: string;
  geomStyle: GeomStyle;
  layerFields: LayerField[];
  classifiersGroups: Classifier[] = [];
  selectedClassifiersGroup: number;
  objectStyleOpened = false;
  layerFieldsOpened = false;
  layerFilterOpened = false;
  selectedBtn: any;
  currentButton: boolean = null;

  childClassifier: boolean = false;
  newClassifiersGroup: string;
  baseClassifierId: number;
  classifierGroup: any[] = [];
  selectizeConfig: any = {
    labelField: 'name',
    valueField: 'id',
    dropdownDirection: 'down',
    highlight: true,
    addPrecedence: true,
    maxItems: 1,
    create: true,
    render: {
      option_create: (data, escape) => {
          return '<div class="create">'+ this.translateService.instant('ADD') +' <strong>' + escape(data.input) + '</strong>&hellip;</div>';
      }
  },
    searchField: ['name'],
    plugins: ['dropdown_direction']
  };

  constructor(private activeModal: NgbActiveModal,
    private validationService: ValidationService,
    private classifiersService: ClassifiersService,
    public toastr: LocalizedToastrService,
    public translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.classifiersService.getChildClassifiers(1).then(c => {
      this.classifiersGroups = c;
    });
    if (this.classifier.id1) {
      this.classifierGroup.push(this.classifier.id1);
    }
    if (this.classifier.geomStyle) {
      this.geomStyle = this.classifier.geomStyle;
    } else {
      this.geomStyle = new GeomStyle();
    }
    if (!this.geomStyle.labelRules) {
      this.geomStyle.labelRules = [];
    }
    if (this.geomStyle.labelRules.findIndex(r => r.rule === 'Default') === -1) {
      const defaultStyle = new GeomStyle;
      // No need for labelRules in Default rule (LabelRules are only for main GeomStyle)
      delete defaultStyle.labelRules;
      defaultStyle.rule = 'Default';
      this.geomStyle.labelRules.push(defaultStyle);
      this.geomStyle.rule = defaultStyle.rule;
    }

    this.layerFields = this.classifier.layerFields;
    if (!this.layerFields) {
      this.layerFields = [];
    }

    this.currentButton = this.classifier.allObjectsPublic;

    if (this.duplicateClassifier) {
      this.duplicateClassifierFrom = this.classifier.name;
    }

    if (!this.classifier.ruleset) {
      this.classifier.ruleset = {
        condition: 'and',
        rules: []
      };
    }
  }

  toggleGeometriesPublicState(data) {
    if (data && data.target) {
      this.currentButton = JSON.parse(data.target.value);
    }
  }

  checkRenderingOption() {
    return environment.enableServerRendering || false;
  }

  getModalTitle() {
    if (this.duplicateClassifier) {
      return 'CLASSIFIERS.DUPLICATE_CLASSIFIER';
    }

    if (!this.classifier.id) {
      return 'CLASSIFIERS.ADD_CLASSIFIER';
    }

    if (this.classifier.id) {
      return this.childClassifier
        ? 'CLASSIFIERS.EDIT_CLASSIFIER'
        : 'CLASSIFIERS.EDIT_CLASSIFIER_GROUP';
    }
  }

  onSubmit(form) {
    if (!form.valid || !this.validateNumberFields()) {
      this.validationService.markAllDirty(form.form);
      return;
    }
    if (!this.childClassifier) {
      if (this.baseClassifierId == 2) {
        delete this.classifier.ruleset;
      }
      this.activeModal.close(this.classifier);
    }
    else {
      for (const key in this.layerFields) {
        if (!this.layerFields[key].name) {
          this.toastr.error('CLASSIFIERS.ERROR_LAYER_FIELD_NAME');
          return;
        }
      }
      // Save current style changes to selected rule.
      this.saveGeomStyleChanges();
      // Classifier geomStyle should always be set to 'Default' rule (no matter what style rule
      // was selected before)
      this.setDefaultGeomStyle();
      this.classifier.layerFields = this.layerFields;
      this.classifier.geomStyle = this.geomStyle;

      if (!this.newClassifiersGroup) {
        this.classifier.id1 = this.selectedClassifiersGroup || this.classifier.id1;
      }
      else {
        this.classifier.parentName = this.newClassifiersGroup;
      }

      this.classifier.allObjectsPublic = this.currentButton;

      this.activeModal.close(this.classifier);
    }
  }

  getClassifierModel(classifier) {
    if (!classifier) {
      return;
    }
    const chosenGroup = _.find(this.classifiersGroups, c => c.id == classifier);
    if (chosenGroup != null) {
      this.selectedClassifiersGroup = classifier;
      this.newClassifiersGroup = null;
    }
    else {
      this.newClassifiersGroup = classifier;
      this.selectedClassifiersGroup = null;
    }
  }

  onToggleAdvancedSettings(toggledOn: boolean) {
    if (!this.modalWindow) {
      return;
    }
    this.modalWindow.size = toggledOn ? 'lg' : 'md';
  }

  toggleClassifierParameter(name: string): void {
      if(name) this.classifier[name] = !this.classifier[name];
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  validateNumberFields() {
    if (this.geomStyle.pointRadius < 0 || this.geomStyle.lineWidth < 0 || this.geomStyle.labelSize < 0 || this.geomStyle.pointSize < 0) {
      return false;
    }
    return true;
  }

  saveGeomStyleChanges() {
    if (!this.geomStyle.labelRules) {
      this.geomStyle.labelRules = [];
    }
    if (!this.geomStyle.rule) {
      return;
    }
    // Style rule to update later
    const objToPush = {
      fillColor: this.geomStyle.fillColor,
      lineColor: this.geomStyle.lineColor,
      pointColor: this.geomStyle.pointColor,
      pointBgColor: this.geomStyle.pointBgColor,
      fillPatternColor: this.geomStyle.fillPatternColor,
      linePatternColor: this.geomStyle.linePatternColor,
      labelSize: this.geomStyle.labelSize,
      labelColor: this.geomStyle.labelColor,
      labelFont: this.geomStyle.labelFont,
      labelEnabled: this.geomStyle.labelEnabled,
      lineWidth: this.geomStyle.lineWidth,
      lineStyle: this.geomStyle.lineStyle,
      objLabel: this.geomStyle.objLabel,
      rule: this.geomStyle.rule,
      pointIcon: this.geomStyle.pointIcon,
      pointSize: this.geomStyle.pointSize,
      pointForm: this.geomStyle.pointForm,
      pointRadius: this.geomStyle.pointRadius,
      pointRotation: this.geomStyle.pointRotation,
      pointGradient: this.geomStyle.pointGradient,
      fillPattern: this.geomStyle.fillPattern,
      strokePattern: this.geomStyle.strokePattern
    };
    // Update current style rule
    const currentRuleIndex = this.geomStyle.labelRules.findIndex(labelRule => labelRule.rule === this.geomStyle.rule);
    this.geomStyle.labelRules[currentRuleIndex] = objToPush;
  }

  setDefaultGeomStyle() {
    if (this.geomStyle.rule === 'Default') {
      return;
    }
    const defaultStyle = this.geomStyle.labelRules.find(r => r.rule === 'Default');

    if (!defaultStyle) {
      return;
    }
    this.geomStyle.fillColor = defaultStyle.fillColor;
    this.geomStyle.lineColor = defaultStyle.lineColor;
    this.geomStyle.pointColor = defaultStyle.pointColor;
    this.geomStyle.pointBgColor = defaultStyle.pointBgColor;
    this.geomStyle.fillPatternColor = defaultStyle.fillPatternColor;
    this.geomStyle.linePatternColor = defaultStyle.linePatternColor;
    this.geomStyle.labelSize = defaultStyle.labelSize;
    this.geomStyle.labelColor = defaultStyle.labelColor;
    this.geomStyle.labelFont = defaultStyle.labelFont;
    this.geomStyle.labelEnabled = defaultStyle.labelEnabled;
    this.geomStyle.lineWidth = defaultStyle.lineWidth;
    this.geomStyle.lineStyle = defaultStyle.lineStyle;
    this.geomStyle.objLabel = defaultStyle.objLabel;
    this.geomStyle.rule = defaultStyle.rule;
    this.geomStyle.pointIcon = defaultStyle.pointIcon;
    this.geomStyle.pointSize = defaultStyle.pointSize;
    this.geomStyle.pointForm = defaultStyle.pointForm;
    this.geomStyle.pointRadius = defaultStyle.pointRadius;
    this.geomStyle.pointRotation = defaultStyle.pointRotation;
    this.geomStyle.pointGradient = defaultStyle.pointGradient;
    this.geomStyle.fillPattern = defaultStyle.fillPattern;
    this.geomStyle.strokePattern = defaultStyle.strokePattern;
  }
}

import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { GeometryFilesService } from '@pgis/core/services/geometry-files.service';
import { GeometryFiles } from '@pgis/shared';
import { GeometryFileModalComponent } from '@pgis/views/dashboard/geometry-file-modal';
import { LocalizedToastrService } from '@pgis/core/services/localized-toastr.service';
import { ConfirmDialogService } from '@pgis/services/confirm-dialog.service';

@Component({
  selector: 'pgis-object-files-tab',
  templateUrl: './object-files-tab.component.html',
  styleUrls: ['./object-files-tab.component.scss']
})
export class ObjectFilesTabComponent implements OnInit {

  @ViewChild('downloadLink') private downloadLink: ElementRef;
  geometryFiles: GeometryFiles[];

  @Input()
  objectId: number;

  constructor(private geometryFilesService: GeometryFilesService,
    private toastr: LocalizedToastrService,
    private translateService: TranslateService,
    private confirmDialogService: ConfirmDialogService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.geometryFilesService.getGeometryFiles(this.objectId).then(files => {
      this.geometryFiles = files;
    });
  }

  uploadFile() {
    const modalRef = this.modalService.open(GeometryFileModalComponent);
    modalRef.componentInstance.geometryId = this.objectId;
    modalRef.result.then((fileData) => {
      this.geometryFilesService.addFiles(fileData).then(data => {
        this.geometryFilesService.getGeometryFiles(this.objectId).then(files => {
          this.geometryFiles = files;
        });
        this.toastr.success('MAP.FILES_UPLOADED');
      },
        error => {
          console.log('Error: ' + error);
          this.toastr.error('MAP.ERROR_UPLOADING_FILES');
        });
    }, () => {
    });
  }

  async downloadFile(fileData) {
    const blob = await this.geometryFilesService.downloadFile(fileData.geometryId, fileData.id);
    const url = window.URL.createObjectURL(blob);

    const link = this.downloadLink.nativeElement;
    link.href = url;
    link.download = fileData.name;
    link.click();

    window.URL.revokeObjectURL(url);
  }

  deleteFile(fileData) {
    this.confirmDialogService.showConfirmationDialog(this.translateService.instant('CONFIRM_DELETE', { object: fileData.name })).then(
      (confirmed) => {
        if (confirmed) {
          this.geometryFilesService.deleteFile(fileData.geometryId, fileData.id).then(result => {
            this.geometryFilesService.getGeometryFiles(this.objectId).then(files => {
              this.geometryFiles = files;
            });
            this.toastr.success('MAP.FILE_DELETED');
          },
            error => {
              console.log('Error: ' + error);
              this.toastr.error('ERROR');
            });
        }
      });
  }
}

import { Injectable } from '@angular/core';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import olPolygon from 'ol/geom/Polygon';
import olPoint from 'ol/geom/Point';
import olFeature from 'ol/Feature';
import olDraw from 'ol/interaction/Draw';
import WKT from 'ol/format/WKT.js';

import { LayerSource } from './layer-source';
import { GeometriesService } from '../geometries.service';
import { ObjectFilter } from '@pgis/shared/models/object-filter.model';
import { GeomsWithStyles } from '@pgis/shared/models/geoms-with-styles.model';
import { Classifier } from '@pgis/shared';

@Injectable({
  providedIn: 'root'
})
export class PolygonSourceService extends LayerSource {

  type: string = 'Polygon';
  source: VectorSource;
  loaded: {};
  objectFilter: ObjectFilter;

  constructor(private geometriesService: GeometriesService) {
    super();
  }

  loadGeometries(extent: number[], zoom: number, classifiersToLoad: number[]): Promise<void> {
    return this.geometriesService.getPolygons(extent, zoom, classifiersToLoad, this.loaded, this.objectFilter).then((geomFeatures: GeomsWithStyles) => {
      this.singleLayerToLoad = null;
      if (!geomFeatures.features) {
        return;
      }
      for (const geomFeature of geomFeatures.features) {
        geomFeature.geomStyle = geomFeatures.geomStyles[geomFeature.geomStyleIndex].geomStyle;
//        const polyExteriorCoordinates: any[] = [];
//        for (const g of geomFeature.geom) {
//          polyExteriorCoordinates.push([g.x, g.y]);
//        }
        const format = new WKT();
        const geom = format.readGeometryFromText(geomFeature.geom);
        const oFeature = new olFeature({
          geometry: geom,
          //labelPoint: new olPoint(polyExteriorCoordinates[0]),
          name: geomFeature.name
        });
        this.addFeatureToSource(oFeature, geomFeature);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getLayer(): VectorLayer {
    return new VectorLayer({
      source: this.source,
      renderBuffer: 1000
    });
  }

  getDrawIntersection() {
    return new olDraw({
      type: 'Polygon',
      source: this.source,
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import ImageLayer from 'ol/layer/Image'
import ImageWMS from 'ol/source/ImageWMS.js';
import XYZ from 'ol/source/XYZ';
import * as Proj from 'ol/proj';
import * as Control from 'ol/control';
import olPolygon from 'ol/geom/Polygon';
import olLineString from 'ol/geom/LineString';
import Feature from 'ol/Feature';
import olSelectInteraction from 'ol/interaction/Select';
import WKT from 'ol/format/WKT';
import olFeature from 'ol/Feature';
import Style from 'ol/style/Style';
import { Circle as CircleStyle } from 'ol/style.js';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Text from 'ol/style/Text';
import BingMaps from 'ol/source/BingMaps';
import TileWMS from 'ol/source/TileWMS';
import Overlay from 'ol/Overlay';
import VectorSource from 'ol/source/Vector';
import olPointerInteraction from 'ol/interaction/Pointer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { DrawObjectModalComponent } from '@pgis/shared/components/draw-object-modal/draw-object-modal.component';
import { MapMeassureService } from './map.meassure.service';
import { GeometriesService } from '../geometries.service';
import { GeometryFilesService } from '../geometry-files.service';
import { ObjectDragService } from './object-drag.service';

import { LocalizedToastrService } from '@pgis/core/services/localized-toastr.service';
import { VectorSourceProviderService } from './vector-source-provider.service';
import { User, ObjectFilter, Classifier, MapActions, DrawObject } from '@pgis/shared/models';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  map: Map;
  mapBoxLayer: TileLayer;
  view: View;

  drawInteraction = null;
  baseLayers = [];

  overlayLayers = [];
  serversideLayers = {};
  layers = [];

  overlay: Overlay;

  selectedElementDefaultStyle: Style;
  selectedElement: any;
  isNewElementSelected: boolean = false;
  isUserInteraction: boolean;
  mapViews: any[] = [];

  imageArray: any[] = [];

  public geometryDrawn = new Subject();
  currentAction: MapActions;

  private zoomEventFired: boolean;

  private _selectedObject = new Subject();
  public selectedObject = this._selectedObject.asObservable();

  url: string;

  constructor(private http: HttpClient,
    private modalService: NgbModal,
    private mapMeassureService: MapMeassureService,
    private translateService: TranslateService,
    private geometriesService: GeometriesService,
    private toastr: LocalizedToastrService,
    private vectorSourceProviderService: VectorSourceProviderService,
    private geometryFilesService: GeometryFilesService,
    private objectDragService: ObjectDragService) {
    this.translateService.onLangChange.subscribe(data => {
      if (this.overlay) this.overlay.setPosition(undefined);
    });
  }
  /**
   * Returns tuple - first base layers loaded promise, second vector layers loaded promise
   */
  private defineLayers(): Promise<any[]> {
    this.layers = [];
    this.vectorSourceProviderService.initAll();
    const currentUserInfo: User = JSON.parse(localStorage.getItem('currentUser'));
    this.layers.push(...this.vectorSourceProviderService.getAllLayers());
    return this.loadLayers();
  }

  initMap(extent?: number[], zoomOnObject: boolean = false): Promise<void> {
    return this.defineLayers().then(layers => {

      const container = document.getElementById('popup');
      const content = document.getElementById('popup-content');
      const mapContainer = document.getElementById('map');
      const idLabel = this.translateService.instant('OBJECT_KEYS.id');
      const nameLabel = this.translateService.instant('OBJECT_KEYS.name');
      const descriptionLabel = this.translateService.instant('OBJECT_KEYS.description');
      const commentLabel = this.translateService.instant('COMMENT');
      const statusLabel = this.translateService.instant('TASKS.STATUS');

      this.overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250
        }
      });

      this.baseLayers = _.filter(layers, { 'overlay': false });
      this.overlayLayers = _.filter(layers, { 'overlay': true });

      const lastMapView = JSON.parse(localStorage.getItem('lastMapView'));
      if (lastMapView) {
        this.mapViews.push(lastMapView);
        this.view = new View(lastMapView);
      } else {
        const defaultView = {
          center: Proj.fromLonLat([23.9890828, 56.9713962]),
          zoom: 10,
        };
        this.mapViews.push(defaultView);
        this.view = new View(defaultView);
      }

      const mergeLayers = _.concat(_.concat(this.baseLayers.map(f => f.olLayer), this.overlayLayers.map(f => f.olLayer)), this.layers);
      this.map = new Map({
        target: 'map',
        layers: mergeLayers,
        view: this.view,
        controls: Control.defaults(),
        overlays: [this.overlay],
      });

      if (extent) {
        this.zoomToExtent(extent, zoomOnObject);
      }

      const mouseStillMoving = [];
      let mouseOnMap = false;
      this.map.once('postrender',
        () => {
          mapContainer.onmouseleave =
            () => {
              mouseOnMap = false;
            };
          this.map.on('pointermove',
            (evt) => {
              if (evt.dragging) {
                return;
              }

              mouseStillMoving.push(true);
              mouseOnMap = true;
              setTimeout(async () => {
                mouseStillMoving.shift();
                if (mouseStillMoving[0] || !mouseOnMap) {
                  this.overlay.setPosition(undefined);
                  return;
                }
                const feature = this.map.forEachFeatureAtPixel(evt.pixel,
                  (featureAtPixel, layer) => {
                    if (!layer) {
                      return null;
                    }
                    return featureAtPixel;
                  });
                if (!feature) {
                  this.overlay.setPosition(undefined);
                  return;
                }

                const props = feature.getProperties();
                if (!props.id) {
                  return;
                }
                const fullData = await this.geometriesService.getGeometryData(feature.id_);

                if (!(props.description || props.valdosaSugaNosaukums)) {
                  return;
                }

                this.geometryFilesService.getUploadedImages(props.id).then(rez => {
                  this.imageArray = rez || [];

                  if (this.imageArray.length === 0) {
                    content.innerHTML = '<div></div>';
                  }
                  else {
                    switch (this.imageArray.length) {
                      case 1: {
                        content.innerHTML = '<div style="display: inline-flex"><div><img src="' + this.imageArray[0] + '" class="image" style="max-width: 100%; max-height: 79px;"></div></div>';
                        break;
                      }
                      case 2: {
                        content.innerHTML = '<div style="display: inline-flex"><div><img src="' + this.imageArray[0] + '" class="image" style="max-width: 100%; max-height: 79px;"></div><div style="vertical-align: top"> <div style="padding: 5px;padding-top: 0px;"><img src="' + this.imageArray[1] + '" class="image" style="max-width: 100%; max-height: 37px;"></div></div></div>';
                        break;
                      }
                      case 3: {
                        content.innerHTML = '<div style="display: inline-flex"><div><img src="' + this.imageArray[0] + '" class="image" style="max-width: 100%; max-height: 79px;"></div><div style="vertical-align: top"> <div style="padding: 5px;padding-top: 0px;"><img src="' + this.imageArray[1] + '" class="image" style="max-width: 100%; max-height: 37px;"></div><div style="padding: 5px;padding-top: 0px;"><img src="' + this.imageArray[2] + '" class="image" style="max-width: 100%; max-height: 37px;"></div></div></div>';
                        break;
                      }
                      default: {
                        content.innerHTML = '<div style="display: inline-flex"><div><img src="' + this.imageArray[0] + '" class="image" style="max-width: 100%; max-height: 79px;"></div><div style="vertical-align: top"> <div style="padding: 5px;padding-top: 0px;"><img src="' + this.imageArray[1] + '" class="image" style="max-width: 100%; max-height: 37px;"></div><div style="padding: 5px;padding-top: 0px;"><img src="' + this.imageArray[2] + '" class="image" style="max-width: 100%; max-height: 37px;"></div></div><strong> +' + (this.imageArray.length - 3) + '</strong></div>';
                        break;
                      }
                    }
                  }

                  content.innerHTML += '<div><strong>' + idLabel + ': </strong>' + props.id + '</div>';
                  content.innerHTML += '<div><strong>' + nameLabel + ': </strong>' + props.name + '</div>';

                  content.innerHTML += '<div><strong>' + descriptionLabel + ': </strong>' + (props.description || props.valdosaSugaNosaukums) + '</div>';
                  if (fullData.data) {
                    Object.entries(JSON.parse(fullData.data)).forEach(entry => {
                      const key = entry[0];
                      const value = entry[1];
                      content.innerHTML += '<div><strong>' + key + ': </strong>' + value + '</div>';

                    });
                  }
                  content.innerHTML += '<div><strong>' + commentLabel + ': </strong>' + (props.latestComment ? props.latestComment.content : '---') + '</div>';
                  content.innerHTML += '<div><strong>' + statusLabel + ': </strong>' + (props.latestComment ? props.latestComment.status : '---') + '</div>';
                  this.overlay.setPosition(evt.coordinate);
                });

              },
                1000);
            });

          this.reload();
        });

      this.map.on('click',
        () => {
          this.isUserInteraction = true;
        });

      this.map.on('pointerdrag',
        () => {
          this.isUserInteraction = true;
        });

      this.map.getView().on('change:resolution',
        e => {
          this.zoomEventFired = true;
          this.isUserInteraction = true;
        });

      this.map.on('moveend',
        (e) => {
          if (!this.isUserInteraction) {
            return;
          }
          if (this.zoomEventFired) {
            this.reloadLayerData();
            this.overlay.setPosition(undefined);
            this.zoomEventFired = false;
          }
          this.isUserInteraction = false;
          this.rememberView();
        });
      return;
    });

  }

  private zoomToExtent(extent: number[], zoomOnObject: boolean = false): void {
    const view = this.map.getView();
    const mapSize = this.map.getSize();
    if (!view || !mapSize) {
      return;
    }
    if (!zoomOnObject) {
      view.fit(extent, mapSize, { maxZoom: 28 });
      this.rememberView();
    }
    else {
      view.fit(extent, { size: mapSize, maxZoom: 20 });
      this.rememberView(zoomOnObject);
    }
  }

  private rememberView(zoomOnObject?: boolean): void {
    const viewZoom = zoomOnObject && this.map.getView().getZoom() > 20 ? 20 : this.map.getView().getZoom();
    const currentView = {
      center: this.map.getView().getCenter(),
      zoom: viewZoom,
      maxZoom: zoomOnObject ? 20 : 28
    };

    localStorage.setItem('lastMapView', JSON.stringify(currentView));
    this.mapViews.push(currentView);
    if (this.mapViews.length > 5) {
      this.mapViews.shift();
    }
  }

  toPreviousView() {
    if (this.mapViews.length < 2) { // array must contain at least current view and previous view
      return;
    }
    this.overlay.setPosition(undefined);
    this.mapViews.pop(); // remove current view from array

    const previeousView = this.mapViews[this.mapViews.length - 1]; //[this.currentMapView];
    if (!previeousView) {
      return;
    }
    this.isUserInteraction = false;
    const view = new View(previeousView);
    view.on('change:resolution',
      e => {
        this.isUserInteraction = true;
      });
    this.map.setView(view);
  }

  private loadLayers(): Promise<any[]> {
    return this.http.get<any>('api/v1/layers').toPromise().then(layers => {
      const newLayers = [];
      const mapLayers = JSON.parse(localStorage.getItem('selectedBaseLayers'));

      let shownLayers = [];
      const currentUserInfo: User = JSON.parse(localStorage.getItem('currentUser'));
      if (!currentUserInfo) {       //temporary fix to hide overlay layers from unaouthorized users
        layers.forEach(l => {
          if (!l.overlay) {
            shownLayers.push(l);
          }
        });
      }
      else {
        shownLayers = layers;
      }

      shownLayers.forEach(data => {
        if (data.layerType === 'TileLayer') {
          switch (data.sourceType) {
            case 'XYZ':
              const xSource = new XYZ(JSON.parse(data.sourceJson));
              xSource.crossOrigin = 'Anonymous';
              newLayers.push({
                name: data.name,
                group: data.group,
                overlay: data.overlay,
                olLayer: new TileLayer({
                  source: xSource,
                  visible: ((!_.isEmpty(mapLayers) && _.filter(mapLayers, l => l.name === data.name).length !== 0) ||   //Remembered selected layer
                    (_.isEmpty(mapLayers) && data.id === 1)) ? true : false   //default Google map layer
                })
              });
              break;
            case 'BingMaps':
              newLayers.push({
                name: data.name,
                group: data.group,
                overlay: data.overlay,
                olLayer: new TileLayer({
                  source: new BingMaps(JSON.parse(data.sourceJson)),
                  visible: (!_.isEmpty(mapLayers) && _.filter(mapLayers, l => l.name === data.name).length !== 0) ? true : false
                })
              });
              break;
            case 'TileWMS':
              const layerConfig = JSON.parse(data.sourceJson);
              newLayers.push({
                name: data.name,
                group: data.group,
                overlay: data.overlay,
                olLayer: new TileLayer({
                  source: new TileWMS({ ...layerConfig, crossOrigin: 'anonymous' }),
                  visible: (!_.isEmpty(mapLayers) && _.filter(mapLayers, l => l.name === data.name).length !== 0) ? true : false
                })
              });
              break;
          }
        }
      });
      return newLayers;
    });
  }

  setFilters(visibleClassifiers: number[], objectFilter?: ObjectFilter) {
    this.vectorSourceProviderService.setVisibleClassifiers(visibleClassifiers);
    this.vectorSourceProviderService.setObjectFilter(objectFilter);
    this.vectorSourceProviderService.clearAll();
  }

  toggleLayer(classifier: Classifier): any {
    const extent = this.map.getView().calculateExtent(this.map.getSize());
    const resolution = this.map.getView().getResolution();
    this.overlay.setPosition(undefined);
    if (!classifier.serverside) {
      this.vectorSourceProviderService.toggleLayer(classifier, extent, resolution);
    } else {
      this.vectorSourceProviderService.clearOldGeometries(classifier);
      let allLayers = this.map.getLayers();
      if (classifier.checked) {
        let c = 0;

        allLayers.forEach(f => {
          if (f.type != 'VECTOR') {
            c++;
          }
        });

        let wmsLayer = new ImageLayer({
          source: new ImageWMS({
            'url': '/api/v1/proxy/wmsLayers/' + classifier.id,
            'params': { 'LAYERS': classifier.id + '_lines,' + classifier.id + '_polygons,' + classifier.id + '_points' },
            crossOrigin: 'anonymous'
          })
        });
        this.serversideLayers[classifier.id] = wmsLayer;
        allLayers.insertAt(c, wmsLayer);
      } else {
        allLayers.remove(this.serversideLayers[classifier.id]);
        delete this.serversideLayers[classifier.id];
      }

    }

  }

  setDrawLine() {
    this.currentAction = MapActions.DrawLine;
    this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }

    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }

    this.mapMeassureService.disable();
    this.drawInteraction = this.vectorSourceProviderService.getDrawIntersection('LineString');

    this.map.addInteraction(this.drawInteraction);
    this.drawInteraction.on('drawend', (data) => this.onDrawEnd(data));

  }

  setDrawPoint() {
    this.currentAction = MapActions.DrawPoint;
    this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }
    this.mapMeassureService.disable();
    this.drawInteraction = this.vectorSourceProviderService.getDrawIntersection('Point');

    this.map.addInteraction(this.drawInteraction);
    this.drawInteraction.on('drawend', (data) => this.onDrawEnd(data));
  }

  setSelectElement() {
    this.currentAction = MapActions.SelectObject;
    this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }
    this.mapMeassureService.disable();
    this.drawInteraction = new olSelectInteraction({
      style: new Style({
        fill: new Fill({
          color: '#FF0000'
        }),
        stroke: new Stroke({
          color: '#000000',
          width: 5
        }),
        image: new CircleStyle({
          radius: 15,
          fill: new Fill({ color: '#666666' }),
          stroke: new Stroke({ color: '#bada55', width: 1 })
        })

      }),
      hitTolerance: 4
    });
    let handleEvent = this.drawInteraction.handleEvent;

    this.drawInteraction.handleEvent = (event) => {
      var eventType = event.type;


      this.isNewElementSelected = false;
      var f = handleEvent.bind(this.drawInteraction);
      let result = f(event);

      if (eventType == 'singleclick' && !this.isNewElementSelected) {
        let c = event.coordinate;
        const selectedLayers: number[] = JSON.parse(localStorage.getItem('selectedLayers')) || [];
        this.geometriesService.getNearestGeometryId(c[0], c[1], this.currentZoom, selectedLayers).then(f => {

          if (!f.noDataFound) {
            this._selectedObject.next({ id: f.data.id });
            const format = new WKT();
            const geom = format.readGeometryFromText(f.data.geom);
            const oFeature = new olFeature({
              geometry: geom,
              name: 'selectd geom'
            });
            oFeature.setId(1);
            this.drawInteraction.getOverlay().getSource().clear();
            this.drawInteraction.getOverlay().getSource().addFeature(oFeature);
          }
          else {
            this._selectedObject.next(null);
          }
        });
      }
      return result;

    }

    this.map.addInteraction(this.drawInteraction);
    this.drawInteraction.on('select', (data) => this.onSelectEnd(data));
  }

  setDragElement() {
    this.currentAction = MapActions.DragObject;
    this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }
    this.mapMeassureService.disable();
    this.drawInteraction = new olPointerInteraction(
      this.objectDragService.dragOptions()
    );
    this.objectDragService.objectDragMouseUp.subscribe(() => {
      this.overlay.setPosition(undefined);
    });
    this.map.addInteraction(this.drawInteraction);
  }

  setDrawPolygon() {
    this.currentAction = MapActions.DrawPolygon;
    this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }
    this.mapMeassureService.disable();
    this.drawInteraction = this.vectorSourceProviderService.getDrawIntersection('Polygon');

    this.map.addInteraction(this.drawInteraction);
    this.drawInteraction.on('drawend', (data) => this.onDrawEnd(data));
  }

  setMessureTool(type: string) {
    switch (type) {
      case 'line':
        this.currentAction = MapActions.MeassureDistance;
        break;
      case 'area':
        this.currentAction = MapActions.MeassureArea;
        break;
    }
    this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }
    this.mapMeassureService.disable();
    this.drawInteraction = this.mapMeassureService.enable(this.map, type);
  }

  deselectElement() {
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
  }

  private onSelectEnd(data) {
    this.isNewElementSelected = true;
    if (data.deselected[0]) {
      data.deselected[0].setStyle(this.selectedElementDefaultStyle);
    }
    if (!data.selected[0]) {
      return;
    }


    this.changeSelectedStyle(data.selected[0]);
  }

  private changeSelectedStyle(selected) {
    this.selectedElementDefaultStyle = _.clone(selected.style_);

    const geom = selected.getGeometry();
    let elementaGeometrija = '';
    if (geom instanceof olPolygon) {
      elementaGeometrija = this.mapMeassureService.formatArea(geom);
    }
    else if (geom instanceof olLineString) {
      elementaGeometrija = this.mapMeassureService.formatLength(geom);
    }

    selected.setStyle(new Style({
      fill: new Fill({
        color: '#FF0000'
      }),
      stroke: new Stroke({
        color: '#000000',
        width: 5
      }),
      image: new CircleStyle({
        radius: 15,
        fill: new Fill({ color: '#666666' }),
        stroke: new Stroke({ color: '#bada55', width: 1 })
      })
    }));

    this.selectedElement = selected;
    const selectedObj = selected.getProperties();
    selectedObj.elementaGeometrija = elementaGeometrija;
    delete selectedObj.geometry;
    delete selectedObj.labelPoint;
    if (selectedObj.data) {
      selectedObj.data = JSON.parse(selectedObj.data);
    }
    this._selectedObject.next(selectedObj);
  }

  private onDrawEnd(data) {
    this.currentAction = MapActions.None;
    const wkt = new WKT();

    this.modalService.open(DrawObjectModalComponent).result.then((result: { drawObject: DrawObject, geometryFiles: FormData }) => {
      this.geometriesService
        .addGeometry(wkt.writeFeature(data.feature), result.drawObject.name, result.drawObject.description, result.drawObject.classId, result.drawObject.isPublic, result.drawObject.data, result.drawObject.userEmail)
        .then(async (dt) => {
          console.log(dt);
          if (result.geometryFiles) {
            result.geometryFiles.append('geometryId', dt.toString());
            await this.geometryFilesService.addFiles(result.geometryFiles);
          }
          this.geometryDrawn.next(true);
          this.toastr.success('MAP.OBJECT_ADDED');
        })
        .catch((err) => {
          data.target.source_.removeFeature(data.feature);
          this.toastr.error('MAP.ERROR_OBJECT_ADD');
        });
    },
      (reason) => {
        data.target.source_.removeFeature(data.feature);
      });
  }

  updateFeature(feature: DrawObject) {
    return this.geometriesService.updateGeometry(feature);
  }

  deleteFeature(id) {
    this.geometriesService.deleteGeometry(id).then(rez => {
      if (this.vectorSourceProviderService.removeFeature(id)) {
        this.setSelectElement();
        this.reload();
      }
    });
  }

  reload() {
    if (!this.map) {
      return;
    }
    this.map.updateSize();
    this.map.renderSync();
  }

  zoomToLayer(classifier: Classifier, objectFilter?: ObjectFilter) {
    this.geometriesService.getExtent(classifier.id, objectFilter).then(extentCoordinates => {
      this.zoomToExtent(extentCoordinates, true);
    });
  }

  reloadLayerData() {
    this.vectorSourceProviderService.clearAll();
  }

  getVisibleFeatures(): Feature[] {
    const visibleFeatures: Feature[] = [];
    const extent = this.map.getView().calculateExtent(this.map.getSize());
    this.vectorSourceProviderService.getAllLayerSources().forEach((source: VectorSource) => {
      source.forEachFeatureInExtent(extent, (feature: Feature) => {
        visibleFeatures.push(feature);
      });
    });

    return visibleFeatures;
  }

  get currentZoom(): number | null {
    if (!this.map) {
      return null;
    }
    return this.map.getView().getZoom().toFixed();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LocalizedRouterService } from '@pgis/core/services/localized-router.service';
import { AuthenticationService } from '@pgis/core/services/authentication.service';

@Component({
  selector: 'pgis-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {

  isAuthenticated: boolean;

  constructor(private localizedRouter: LocalizedRouterService,
    private router: Router,
    private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.isAuthenticated = !!this.authenticationService.getCurrentUser();
  }

  isMapRoute() {
    return this.localizedRouter.isMapRoute() || this.router.url.toLowerCase().indexOf('/dashboard') >= 0;  //map view or object task on map
  }
}

export class Companies {

  public id: number;
  public name: string;
  public streetAddress: string;
  public postAddress: string;
  public email: string;
  public postIndex: string;
  public logoImage: string;
  public phone: string;
  public registrationNumber: string;
  public vatNumber: string;
  public activeTaskCount: number;
  public finishedTaskCount: number;
  public notes: string;
  public contactFirstName: string;
  public contactLastName: string;
  public webAddress: string;
}

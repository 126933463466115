import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    const keys = [];

    if (!value) {
      return keys;
    }

    for (const key of Object.keys(value)) {
      keys.push({key: key, value: value[key]});
    }
    return keys;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { saveAs } from 'file-saver';

import { Feature } from '@pgis/shared/models/feature.model';
import * as _ from 'lodash';
import { ReportFilter } from '@pgis/shared/models/report-filter.model';
import { ObjectFilter, DrawObject, GeomsWithStyles } from '@pgis/shared/models';

@Injectable({
  providedIn: 'root'
})
export class GeometriesService {

  constructor(private http: HttpClient) {
  }

  addGeometry(wkt: string, name: string, description: string, classId: number, isPublic: boolean, data: any, userEmail: string) {
    return this.http.post<any>('api/v1/geoms/add',
      {
        wkt: wkt,
        name: name,
        description: description,
        classId: classId,
        isPublic: isPublic,
        data: JSON.stringify(data),
        userEmail: userEmail
      }).toPromise();
  }

  updateGeometry(geometry: DrawObject, wkt?: string) {
    return this.http.put<any>('api/v1/geoms/' + geometry.id,
      {
        name: geometry.name,
        description: geometry.description,
        oldClassId: geometry.oldClassId,
        classId: geometry.classId,
        isPublic: geometry.isPublic,
        data: JSON.stringify(geometry.data),
        wkt: wkt || null
      }).toPromise();
  }

  deleteGeometry(id) {
    return this.http.delete('api/v1/geoms/' + id).toPromise();
  }

  upvoteGeometry(id: number): Promise<void> {
    return this.http.post<void>(`api/v1/geoms/${id}/upvote`, null).toPromise();
  }

  getGeometryData(id: number): Promise<Feature> {
    return this.http.get<Feature>('api/v1/geoms/' + id).toPromise();
  }
  getNearestGeometryId(x: number, y: number, zoom: number, visibleClassifiers: number[]): Promise<any> {
    return this.http.post<number>('api/v1/geoms/nearestgeom',
      {
        x: x,
        y: y,
        classifiers: visibleClassifiers || [],
        zoom: zoom
      }).toPromise();
  }

  getPoints(extent: number[], zoom: number, visibleClassifiers: number[], pointsLoaded: any, objectFilter: ObjectFilter): Promise<GeomsWithStyles> {
    return this.http.post<GeomsWithStyles>('api/v1/geoms/points',
      {
        minx: extent[0],
        miny: extent[1],
        maxx: extent[2],
        maxy: extent[3],
        classifiers: visibleClassifiers || [],
        geometriesLoaded: Object.keys(pointsLoaded),
        objectFilter: objectFilter,
        zoom: zoom
      }).toPromise();
  }

  getLines(extent: number[], zoom: number, visibleClassifiers: number[], pointsLoaded: any, objectFilter: ObjectFilter): Promise<GeomsWithStyles> {
    return this.http.post<GeomsWithStyles>('api/v1/geoms/lines',
      {
        minx: extent[0],
        miny: extent[1],
        maxx: extent[2],
        maxy: extent[3],
        classifiers: visibleClassifiers || [],
        geometriesLoaded: Object.keys(pointsLoaded),
        objectFilter: objectFilter,
        zoom: zoom
      }).toPromise();
  }

  getPolygons(extent: number[], zoom: number, visibleClassifiers: number[], pointsLoaded: any, objectFilter: ObjectFilter): Promise<GeomsWithStyles> {
    return this.http.post<GeomsWithStyles>('api/v1/geoms/polygons',
      {
        minx: extent[0],
        miny: extent[1],
        maxx: extent[2],
        maxy: extent[3],
        classifiers: visibleClassifiers || [],
        geometriesLoaded: Object.keys(pointsLoaded),
        objectFilter: objectFilter,
        zoom: zoom
      }).toPromise();
  }

  getExtent(classifierId: number, objectFilter?: ObjectFilter): Promise<number[]> {
    let params = new HttpParams();
    if (objectFilter) {
      Object.keys(objectFilter).forEach(function (key) {
        params = params.append(key, objectFilter[key]);
      });
    }

    return this.http.get<number[]>('api/v1/geoms/extent/' + classifierId, { params: params }).toPromise();
  }

  getFeatureExtent(layerId: any, featureId: any): Promise<number[]> {
    return this.http.get<number[]>(`api/v1/geoms/extent/${layerId}/${featureId}`).toPromise();
  }

  downloadCsv(objectData: DrawObject): Promise<any> {
    return this.http.post<any>(`api/v1/geoms/csv/${objectData.id}`, objectData, { responseType: 'blob' as 'json' }).toPromise().then(res => {
      let fileName = objectData.name || "Exported csv(" + objectData.id + ")";
      saveAs(res, fileName + '.csv');
    });
  }

  getClassObjects(classId: number, reportFilter?: ReportFilter): Promise<Feature[]> {
    let filterParams = new HttpParams();
    if (reportFilter) {
      Object.keys(reportFilter).forEach(key => {
        filterParams = filterParams.append(key, reportFilter[key]);
      });
    }
    return this.http.get<Feature[]>('api/v1/geoms/class-objects/' + classId, { params: filterParams }).toPromise();
  }

  getFeatureData(extent: number[], visibleClassifiers: number[], zoom: number): Promise<Feature[]> {
    return this.http.post<Feature[]>('api/v1/geoms/features', {
      minx: extent[0],
      miny: extent[1],
      maxx: extent[2],
      maxy: extent[3],
      classifiers: visibleClassifiers || [],
      zoom: zoom
    }).toPromise();
  }

  downloadObjectReport(classifierId: number, classifierName: string): Promise<any> {
    return this.http.post<any>(`api/v1/geoms/object-report/${classifierId}`, { id: classifierId, name: classifierName }, { responseType: 'blob' as 'json' }).toPromise().then(res => {
      saveAs(res, classifierName + '_objects.csv');
    });
  }

  addColumnsFromDataJson(features: Feature[]): void {

    // group by classId
    const obj = _.find(features, d => d.data != null);
    const groupedFeatures = features.reduce((groups, current: Feature) => {
      const classId = current['classId'];
      groups[classId] = groups[classId] || [];
      groups[classId].push(current);
      return groups;
    }, {});

    Object.keys(groupedFeatures).forEach(classId => {
      let jsonColumnCount = obj ? Object.keys(JSON.parse(obj.data)).length : 0;
      groupedFeatures[classId].forEach(object => {
        object.dataArr = [];
        if (object.data != null) {
          const dataColumn = JSON.parse(object.data);
          for (const k of Object.keys(dataColumn)) {
            object.dataArr.push({ key: k, value: dataColumn[k] });
          }
          jsonColumnCount = object.dataArr.length;
        }
        else {
          for (let i = 0; i < jsonColumnCount; i++) {
            object.dataArr.push({ key: '', value: '' });
          }
        }
      });
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DrawObject } from '@pgis/shared';
import WKT from 'ol/format/WKT';
import { GeometriesService } from '../geometries.service';
import { LocalizedToastrService } from '../localized-toastr.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObjectDragService {
  private _objectDragMouseUp = new Subject<number>();
  public objectDragMouseUp = this._objectDragMouseUp.asObservable();

  constructor(private http: HttpClient,
    private geometriesService: GeometriesService,
    private toastr: LocalizedToastrService) {

  }

  public dragOptions():object {
    var coordinate_: any[] = [];
    var feature_ : any;
    var cursor_: any;
    var previousCursor_: any;
    return {
      handleDownEvent: (evt) => {
        var map = evt.map;

        var feature = map.forEachFeatureAtPixel(evt.pixel,
          function (feature) {
            return feature;
          });
        if (feature) {
          coordinate_ = evt.coordinate;
          feature_ = feature;
        }

        return !!feature;
      },
      handleDragEvent: (evt) => {
        var deltaX = evt.coordinate[0] - coordinate_[0];
        var deltaY = evt.coordinate[1] - coordinate_[1];

        var geometry = feature_.getGeometry();
        geometry.translate(deltaX, deltaY);

        coordinate_[0] = evt.coordinate[0];
        coordinate_[1] = evt.coordinate[1];
      },
      handleMoveEvent: (evt) => {
        if (cursor_) {
          var map = evt.map;
          var feature = map.forEachFeatureAtPixel(evt.pixel,
            function (feature) {
              return feature;
            });
          var element = evt.map.getTargetElement();
          if (feature) {
            if (element.style.cursor != cursor_) {
              previousCursor_ = element.style.cursor;
              element.style.cursor = cursor_;
            } 
          } else if (previousCursor_ !== undefined) {
            element.style.cursor = previousCursor_;
            previousCursor_ = undefined;
          }
        }
      },
      handleUpEvent: () => {
        this._objectDragMouseUp.next();
        let featureProps = feature_.getProperties();
        if (!featureProps) {
          this.toastr.error('MAP.FAILED_TO_MOVE_FEATURE');
          return false;
        }
        const wkt = new WKT();
        let objectInfo: DrawObject = {
          id: featureProps.id,
          name: featureProps.name,
          description: featureProps.description,
          classId: featureProps.classId,
          oldClassId: featureProps.classId,
          data: featureProps.data || {}
        };
        this.geometriesService.updateGeometry(objectInfo, wkt.writeFeature(feature_)).then(data => {
          this.toastr.success('MAP.FEATURE_MOVED');
          coordinate_ = null;
          feature_ = null;
          return false;
        })
          .catch((err) => {
            this.toastr.error('MAP.FAILED_TO_MOVE_FEATURE');
            coordinate_ = null;
            feature_ = null;
            return false;
          });
      }
    }
  }
}

import { Injectable , OnDestroy } from '@angular/core';
import { ToastsManager } from 'ng6-toastr/ng2-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalizedToastrService implements OnDestroy {

  subscriptions: Subscription[] = [];

  constructor(private toastManager: ToastsManager,
    private translateService: TranslateService) {
  }

  error(messageKey: string, titleKey?: string, options?: any): void {
    this.subscriptions.push(this.translateService.get(this.getTranslationKeys(messageKey, titleKey)).subscribe(
      (res: string[]) => {
        this.toastManager.error(res[messageKey], res[titleKey], options);
      }));
  }

  info(messageKey: string, titleKey?: string, options?: any): void {
    this.subscriptions.push(this.translateService.get(this.getTranslationKeys(messageKey, titleKey)).subscribe((res: string[]) => {
      this.toastManager.info(res[messageKey], res[titleKey], options);
    }));
  }

  success(messageKey: string, titleKey?: string, options?: any): void {
    this.subscriptions.push(this.translateService.get(this.getTranslationKeys(messageKey, titleKey)).subscribe((res: string[]) => {
      this.toastManager.success(res[messageKey], res[titleKey], options);
    }));
  }

  warning(messageKey: string, titleKey?: string, options?: any): void {
    this.subscriptions.push(this.translateService.get(this.getTranslationKeys(messageKey, titleKey)).subscribe((res: string[]) => {
      this.toastManager.warning(res[messageKey], res[titleKey], options);
    }));
  }

  private getTranslationKeys(messageKey: string, titleKey?: string): string[] {
    const translateKeys: string[] = [messageKey];
    if (titleKey !== undefined) {
      translateKeys.push(titleKey);
    }

    return translateKeys;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

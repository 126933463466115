import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { LocalizedToastrService } from '@pgis/core/services/localized-toastr.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'pgis-multi-file-selector',
  templateUrl: './multi-file-selector.component.html',
  styleUrls: ['./multi-file-selector.component.scss']
})
export class MultiFileSelectorComponent implements OnInit {

  @Input()
  showSubmitButton: boolean;

  @Output()
  onUpload: EventEmitter<FormData> = new EventEmitter<FormData>();

  @Output()
  onChange: EventEmitter<FormData> = new EventEmitter<FormData>();

  @ViewChild('geomFile')
  geomFile;

  geometryFiles: FormData;
  uploadedFiles: any = [];
  fileInput: any;
  descriptionInput: any;
  fileDescriptionText: any;

  constructor(private toastr: LocalizedToastrService) { }

  ngOnInit() {
  }

  upload(form: FormGroup): void {
    const fileObject = this.geomFile.nativeElement;
    if (fileObject.files.length === 0) {
      this.toastr.error('MAP.SELECT_FILE');
      return;
    }
    if (fileObject.files[0].size > 100000000) { //max size 100 mb
      this.toastr.error('MAP.FILE_SIZE_EXCEED');
      return;
    }
    for (const file in this.uploadedFiles) {
      if (this.uploadedFiles[file].name === fileObject.files[0].name) {  //file name duplicate check
        this.toastr.error('MAP.FILE_NAME_DUPLICATE');
        return;
      }
    }
    if (!this.geometryFiles) {
      this.geometryFiles = new FormData();
    }
    this.geometryFiles.append('file', fileObject.files[0]);
    this.geometryFiles.append('file_description', form.value.fileDescription || '');
    this.fileDescriptionText = null;
    this.fileInput = null;
    this.uploadedFiles.push({
      name: fileObject.files[0].name,
      description: form.value.fileDescription || '',
    });
    this.onChange.emit(this.geometryFiles);
  }

  deleteTempFile(index: number): void {
    this.uploadedFiles.splice(index, 1);
    const files = this.geometryFiles.getAll('file');
    const fileDescription = this.geometryFiles.getAll('file_description');
    files.splice(index, 1);
    fileDescription.splice(index, 1);
    this.geometryFiles.delete('file');
    this.geometryFiles.delete('fileDescription');
    files.forEach(f => {
      this.geometryFiles.append('file', f);
    });
    fileDescription.forEach(d => {
      this.geometryFiles.append('file_description', d);
    });
    this.onChange.emit(this.geometryFiles);
  }

  submitFileUpload(): void {
    this.onUpload.emit(this.geometryFiles);
  }
}

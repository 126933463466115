import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Comment, Classifier } from '@pgis/shared/models';
import { ValidationService } from '@pgis/core/services/validation.service';
import { ClassifiersService } from '@pgis/core/services/classifiers.service';
import { CommentsService } from '@pgis/core/services/comments.service';
import { ConfirmDialogService } from '@pgis/services/confirm-dialog.service';

@Component({
  selector: 'pgis-object-comments',
  templateUrl: './object-comments.component.html',
  styleUrls: ['./object-comments.component.scss']
})
export class ObjectCommentsComponent implements OnInit {

  @Input()
  objectId: number;

  @Input()
  comments: Comment[];

  commentStatuses: Classifier[];
  comment: Comment;

  constructor(private validationService: ValidationService,
    private classifiersService: ClassifiersService,
    private commentsService: CommentsService,
    private confirmDialogService: ConfirmDialogService,
    private translateService: TranslateService) { }

  async ngOnInit(): Promise<void> {
    this.resetComment();
    this.commentStatuses = await this.classifiersService.getChildClassifiers(3);
  }

  async addComment(form: FormGroup): Promise<void> {
    if (!form.valid) {
      this.validationService.markAllDirty((<any>form).form);
      return;
    }

    const addedComment = await this.commentsService.addComment(this.objectId, this.comment);
    this.comments.push(addedComment);
    this.resetComment();
  }

  async deleteComment(comment: Comment): Promise<void> {
    const confirmed = await this.confirmDialogService.showConfirmationDialog(this.translateService.instant('CONFIRM'));
    if (!confirmed) {
      return;
    }
    await this.commentsService.deleteComment(this.objectId, comment.id);
    const index = this.comments.indexOf(comment);
    this.comments.splice(index, 1);
  }

  private resetComment(): void {
    this.comment = {
      content: '',
      statusId: 0,
      isPublic: false
    };
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ProfileFilesService {

  constructor(private http: HttpClient) {
  }

  importImageZip(formFile: FormData): Promise<any> {
    return this.http.post(`/api/v1/profile-files/import-zip`, formFile).toPromise();
  }

  getCompanyZipFiles(companyId: number): Promise<any> {
    return this.http.get('/api/v1/profile-files/company-zips/' + companyId).toPromise();
  }

  downloadZip(zipFile: string): Promise<any> {
    return this.http.post('api/v1/profile-files/download', { name: zipFile }, { responseType: 'blob' }).toPromise().then(res => {
      saveAs(res, zipFile);
    });
  }

  deleteZip(zipFile: string): Promise<any> {
    return this.http.delete('api/v1/profile-files/delete', { params: { name: zipFile } }).toPromise();
  }

  importCompanyLogo(formFile: FormData): Promise<any> {
    return this.http.post(`api/v1/profile-files/import-logo`, formFile).toPromise();
  }

  loadLogo(companyId: number): Promise<any> {
    return this.http.get('api/v1/profile-files/load-logo/' + companyId).toPromise();
  }

  deleteLogo(companyId: number): Promise<any> {
    return this.http.delete('api/v1/profile-files/delete-logo/' + companyId).toPromise();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeometryFiles } from '@pgis/shared/models';

@Injectable({
  providedIn: 'root'
})
export class GeometryFilesService {

  constructor(private http: HttpClient) {
  }

  getGeometryFiles(geometryId: number): Promise<GeometryFiles[]> {
    return this.http.get<GeometryFiles[]>('/api/v1/geom-files/' + geometryId).toPromise();
  }

  addFiles(files: FormData): Promise<any> {
    return this.http.post<any>('/api/v1/geom-files', files).toPromise();
  }

  downloadFile(geometryId: number, fileId: number): Promise<any> {
    return this.http.get<any>(`/api/v1/geom-files/download/${geometryId}/${fileId}`, { responseType: 'blob' as 'json' }).toPromise();
  }

  deleteFile(geometryId: number, fileId: number): Promise<any> {
    return this.http.delete<any>(`/api/v1/geom-files/delete/${geometryId}/${fileId}`).toPromise();
  }

  getUploadedImages(geometryId: number): Promise<any> {
    return this.http.get<any>('/api/v1/geom-files/images/' + geometryId).toPromise();
  }
}

import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { throwError, Observable, EMPTY } from 'rxjs';
import { flatMap, catchError } from 'rxjs/operators';
import { User } from '@pgis/shared/models';
import { AuthenticationService, LocalizedRouterService, LoaderService } from '@pgis/core/services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private authenticationService: AuthenticationService;
  private router: LocalizedRouterService;
  private loaderService: LoaderService;

  constructor(private injector: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + currentUser.token
        }
      });
    }
    return next.handle(request).pipe(catchError((error, caught) => {
      this.loaderService = this.injector.get(LoaderService);
      if (error.status !== 401 || request.url.endsWith('token/dispose')) {
        this.loaderService.pengindRequests--;
        return throwError(error);
      }

      this.authenticationService = this.injector.get(AuthenticationService);
      this.router = this.injector.get(LocalizedRouterService);

      if (!currentUser) {
        this.router.navigate(['/']);
        this.loaderService.pengindRequests--;
        return throwError(error);
      }

      return this.authenticationService.reauthenticateUser(currentUser.token).pipe(
        flatMap(newToken => {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + newToken
          }
        });
        return next.handle(request);
      }),
      catchError(c => {
        this.authenticationService.logout().subscribe(r => {
            this.router.navigate(['/']);
          },
          err => {
            localStorage.removeItem('currentUser');
            this.router.navigate(['/']);
          });
        return EMPTY;
      }));
    })) as any;
  }
}

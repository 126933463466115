import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as Proj from 'ol/proj';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { isNumeric } from 'rxjs/internal/util/isNumeric';
import { filter } from 'rxjs/operators';

import { MapService } from '@pgis/core/services/map/map.service';
import { LocalizedRouterService } from '@pgis/core/services/localized-router.service';

@Component({
  selector: 'app-breadcrumbs',
  template: `
<div class="div-display">
  <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs" let-last = last>
    <li class="breadcrumb-item"
        *ngIf="breadcrumb.label.title&&breadcrumb.url.substring(breadcrumb.url.length-1) == '/'||breadcrumb.label.title&&last"
        [ngClass]="{active: last}">
      <a *ngIf="!last" [routerLink]="breadcrumb.url">{{breadcrumb.label.title | translate}}</a>
      <span *ngIf="last" [routerLink]="breadcrumb.url">{{breadcrumb.label.title | translate}}</span>
    </li>
  </ng-template>
  <input type="search" #bigSearch id="bigSearch" *ngIf="mapRoute()" class="float-right search-bar" ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="onAddressChange($event)" (change)="onInputChange($event.target.value)" placeholder="{{ 'MAP.ENTER_LOCATION' | translate }}">
  <span #smallSearch id="smallSearch" *ngIf="mapRoute()" class="moile-search-position"><input type="search" class="float-right" ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="onAddressChange($event)" (change)="onInputChange($event.target.value)" placeholder="{{ 'MAP.SEARCH' | translate }}"></span>
  <select class="form-control form-control-sm selector-position float-right" [(ngModel)]="selectedLanguage" (change)="switchLanguage($event.target.value)" [ngClass]="{'language-margin': mapRoute()}">
    <option *ngFor="let lang of languages" value="{{lang.code}}" >{{lang.lang_code}}</option>
  </select>
</div>`,
  styleUrls: ['./app-breadcrumbs.component.scss']
})
export class AppBreadcrumbsComponent implements OnInit {

  breadcrumbs: Array<Object>;
  languages: any = [
    { code: 'en', label: 'English', lang_code: 'EN', icon: 'flag-icon-us' },
    { code: 'lv', label: 'Latviešu', lang_code: 'LV', icon: 'flag-icon-lv' },
    { code: 'ru', label: 'Русский', lang_code: 'RU', icon: 'flag-icon-ru' }
  ];
  selectedLanguage: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localizeRouterService: LocalizeRouterService,
    private mapService: MapService,
    private localizedRouter: LocalizedRouterService
  ) {
    this.selectedLanguage = this.localizeRouterService.parser.currentLang
      ? this.localizeRouterService.parser.currentLang
      : this.localizeRouterService.parser.defaultLang;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      this.showCurrentBreadcrumbs();
    });
  }
  
  ngOnInit(){
    this.showCurrentBreadcrumbs();
  }

  switchLanguage(langCode: string): void {
    this.localizeRouterService.changeLanguage(langCode);
  }

  showCurrentBreadcrumbs() {
    this.breadcrumbs = [];
    let currentRoute = this.route.root,
      url = '';
    do {
      const childrenRoutes = currentRoute.children;
      currentRoute = null;
      // tslint:disable-next-line:no-shadowed-variable
      childrenRoutes.forEach(route => {
        if (route.outlet === 'primary') {
          const routeSnapshot = route.snapshot;
          url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
          this.breadcrumbs.push({
            label: route.snapshot.data,
            url: url
          });
          currentRoute = route;
        }
      });
    } while (currentRoute);
  }

  onAddressChange($event) {
    const lat = $event.geometry.location.lat();
    const lon = $event.geometry.location.lng();
    this.mapService.map.getView().setCenter(Proj.transform([lon, lat], 'EPSG:4326', 'EPSG:3857'));
    //default zoom for all searches
    this.mapService.map.getView().setZoom(15);
  }

  onInputChange(value) {
    if (value.length === 11 && isNumeric(value)) {
      console.log('Meklē kadastru - ' + value);
    }
  }

  mapRoute() {
    return this.localizedRouter.isMapRoute();
  }
}

import { Component, EventEmitter, Input } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { GeomStyle, Classifier } from '@pgis/shared';

@Component({
  selector: 'pgis-print-legend',
  templateUrl: './print-legend.component.html',
  styleUrls: ['./print-legend.component.scss']
})
export class PrintLegendComponent {

  @Input()
  objectData: Classifier[];

  constructor() { }

  showObjectLegend(geomStyle: GeomStyle) {
    if (!geomStyle) {
      return false;
    }
    return (geomStyle.labelRules && geomStyle.labelRules.length > 1) ? true : false;
  }

  returnRuleName(name) {
    let splitRule = null;
    if (name && name.includes('data.')) {
      let split = name.split('.');
      let ruleValue = split[1].split(/=(.+)/);    //spliting '=' only once
      splitRule = ruleValue[1] || "\"\"";
    }
    else if (name) {
      splitRule = name;
    }

    return splitRule;
  }
}

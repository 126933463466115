import { Component } from '@angular/core';
import {
  AuthenticationService,
  LocalizedRouterService
} from '@pgis/core/services';
import * as _ from 'lodash';

@Component({
  selector: 'pgis-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {

  constructor(private authenticationService: AuthenticationService,
    private localizedRouter: LocalizedRouterService) {
  }


  isAuthenticated() {
    return !_.isEmpty(this.authenticationService.getCurrentUser());
  }

  hasPermissions(permissions: string[]) {
    return _.some(permissions, p => this.authenticationService.userHavePermission(p));
  }

  getUserName() {
    return this.authenticationService.getCurrentUser().permissions.userName;
  }

  logout() {
    this.authenticationService.logout().subscribe(r => {
      document.querySelector('body').classList.add('sidebar-hidden');
      this.localizedRouter.navigate(['/auth/login']);
    });
  }
}

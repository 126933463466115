import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { LayerSource, PointSourceService, PolygonSourceService, LineSourceService } from './services/map';

@NgModule({
  providers: [
    { provide: LayerSource, useClass: PolygonSourceService, multi: true },
    { provide: LayerSource, useClass: LineSourceService, multi: true },
    { provide: LayerSource, useClass: PointSourceService, multi: true },
  ]
})
export class CoreModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}

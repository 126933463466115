import { Component } from '@angular/core';
import { MapService } from '@pgis/core/services/map/map.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pgis-base-layers-tab',
  templateUrl: './base-layers-tab.component.html',
  styleUrls: ['./base-layers-tab.component.scss']
})
export class BaseLayersTabComponent {
  changedLayers = [];
  constructor(private mapService: MapService,
  private translateService: TranslateService) { }

  get baseLayers() {
    return this.mapService.baseLayers;
  }

  get overlayLayers() {
    return this.mapService.overlayLayers;
  }

  isEmpty(objects) {
    if (!objects || objects.length === 0) {
      return true;
    }
    return false;
  }

  visibleLayerCount(layers) {
    let i = 0;
    layers.forEach(l => {
        if (l.olLayer.getVisible()) {
            i++;
        }
    });
    if (i > 0 && !this.changedLayers.includes(layers[0].group)) {
        this.changedLayers.push(layers[0].group);
    }
    return i;
}

  toggleBaseLayer(l, checkbox) {
    for (let i = 0; i < this.mapService.baseLayers.length; i++) {
      if (this.mapService.baseLayers[i] !== l) {
        this.mapService.baseLayers[i].olLayer.setVisible(false);  //show only 1 base layer
      }
    }
    const storagedLayers = JSON.parse(localStorage.getItem('selectedBaseLayers'));
    if (storagedLayers) {
      for (let i = storagedLayers.length; i--;) {   //keep only 1 base layer
        if (storagedLayers[i].overlay !== true) {
          storagedLayers.splice(i, 1);
          localStorage.setItem('selectedBaseLayers', JSON.stringify(storagedLayers));
          break;
        }
      }
    }
    l.group = l.group || this.translateService.instant('MAP.GROUPLESS_LAYERS');
    this.toggleOverlayLayer(l, checkbox);
  }

  toggleOverlayLayer(l, checkbox) {
    l.olLayer.setVisible(!l.olLayer.getVisible());
    if (!this.changedLayers.includes(l.group)) {
      this.changedLayers.push(l.group);
    }

    const layer = _.clone(l);
    delete layer.olLayer;
    let storagedLayers: any[] = JSON.parse(localStorage.getItem('selectedBaseLayers')) || [];

    if (checkbox.target.checked) {
      storagedLayers.push(layer);
    } else {
      storagedLayers = storagedLayers.filter(s => s.name !== l.name && s.group !== l.group && s.overlay !== l.overlay);
    }

    localStorage.setItem('selectedBaseLayers', JSON.stringify(storagedLayers));
  }

  checkLayerArr(layerKey) {
    return this.changedLayers.indexOf(layerKey) != -1;
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { GeomStyle, Classifier } from '@pgis/shared/models';
import FontSymbol from 'ol-ext/style/FontSymbol';
import FontAwesomeDef from 'ol-ext/style/FontAwesomeDef';
import { ConfirmDialogService } from '@pgis/services/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedToastrService, ClassifiersService } from '@pgis/core/services';
import * as _ from 'lodash';

@Component({
  selector: 'pgis-layer-style',
  templateUrl: './layer-style.component.html',
  styleUrls: ['./layer-style.component.scss']
})
export class LayerStyleComponent implements OnInit {
  @ViewChild('selectedIcon')
  selectedIcon;

  @Input()
  geomStyle: GeomStyle;

  @Input()
  layerFields: string[];

  @Input()
  classifier: Classifier;

  @Output()
  onToggleAdvancedSettings: EventEmitter<boolean> = new EventEmitter<boolean>();

  iconArray: any = [];
  iconArrayRows: any = [];
  advancedSettings = false;
  selectedRuleIndex: any;
  fs: any = new FontSymbol(FontAwesomeDef);
  fixedDefs: any = [];

  ruleOptions: any = [];

  ruleValue: string;
  ruleKey: any;

  selectizeConfig: any = {
    labelField: 'label',
    valueField: 'label',
    dropdownDirection: 'down',
    highlight: true,
    addPrecedence: true,
    maxItems: 1,
    searchField: 'label',
    plugins: ['dropdown_direction']
  };

  constructor(private confirmDialogService: ConfirmDialogService,
    private translateService: TranslateService,
    private toastr: LocalizedToastrService,
    private classifiersService: ClassifiersService) {
  }

  ngOnInit(): void {
    // FontAwesome objects. Remove object keys and create array of objects for *ngFor.
    const glyphKeys = Object.keys(this.fs.defs.glyphs);
    for (const glyph of glyphKeys) {
       this.fixedDefs.push(this.fs.defs.glyphs[glyph]);
    }
    if (this.layerFields) {
      this.layerFields.forEach(layerField => {
        this.ruleOptions.push({ label: layerField['name'] });
      });
    }
    if (this.geomStyle.rule && this.geomStyle.rule.includes('.') && this.geomStyle.rule.split('.')[1].includes('=')) {
      this.ruleKey = this.geomStyle.rule.split('.')[1].split('=')[0];
    } else {
      this.ruleKey = this.geomStyle.rule.split('.')[1];
    }
    this.ruleValue = this.geomStyle.rule.split('=')[1];
    if (!this.selectedRuleIndex) {
      this.selectedRuleIndex = this.geomStyle.labelRules.findIndex(r => r.rule === 'Default');
    }
  }

  onRuleAdd() {
    if (!this.ruleKey) {
      return;
    }
    if (!this.geomStyle.labelRules) {
      this.geomStyle.labelRules = [];
    }
    // Get existing labelRule ID by searching for Rule
    let labelRuleId;
    if (this.ruleValue) {
      labelRuleId = this.geomStyle.labelRules.findIndex(labelRule => labelRule.rule === 'data.' + this.ruleKey + '=' + this.ruleValue);
    } else {
      labelRuleId = this.geomStyle.labelRules.findIndex(labelRule => labelRule.rule === 'data.' + this.ruleKey);
    }
    // Style rule to update or create later
    const objToPush = {
      fillColor: this.geomStyle.fillColor,
      lineColor: this.geomStyle.lineColor,
      pointColor: this.geomStyle.pointColor,
      pointBgColor: this.geomStyle.pointBgColor,
      fillPatternColor: this.geomStyle.fillPatternColor,
      linePatternColor: this.geomStyle.linePatternColor,
      labelSize: this.geomStyle.labelSize,
      labelColor: this.geomStyle.labelColor,
      labelFont: this.geomStyle.labelFont,
      labelEnabled: this.geomStyle.labelEnabled,
      lineWidth: this.geomStyle.lineWidth,
      lineStyle: this.geomStyle.lineStyle,
      objLabel: this.geomStyle.objLabel,
      rule: 'data.' + this.ruleKey + '=' + this.ruleValue,
      pointIcon: this.geomStyle.pointIcon || '',
      pointSize: this.geomStyle.pointSize,
      pointForm: this.geomStyle.pointForm,
      pointRadius: this.geomStyle.pointRadius,
      pointRotation: this.geomStyle.pointRotation,
      pointGradient: this.geomStyle.pointGradient,
      fillPattern: this.geomStyle.fillPattern,
      strokePattern: this.geomStyle.strokePattern
    };
    if (this.ruleValue) {
      objToPush.rule = 'data.' + this.ruleKey + '=' + this.ruleValue;
    } else {
      objToPush.rule = 'data.' + this.ruleKey;
    }
    // If labelRule exists, update it's style, otherwise - create new
    if (labelRuleId !== -1) {
      this.geomStyle.labelRules[labelRuleId] = objToPush;
    } else {
      this.geomStyle.labelRules.push(objToPush);
      this.selectedRuleIndex = this.geomStyle.labelRules.length - 1;
      this.onLabelListChange(this.geomStyle.labelRules.length - 1);
    }
  }

  toggleAdvancedSettings() {
    this.advancedSettings = !this.advancedSettings;
    this.onToggleAdvancedSettings.next(this.advancedSettings);
  }

  onRuleDel() {
    if (!this.selectedRuleIndex || this.geomStyle.labelRules[this.selectedRuleIndex].rule === 'Default') {
      return;
    }
    this.geomStyle.labelRules.splice(this.selectedRuleIndex, 1);
    const defaultStyleIndex = this.geomStyle.labelRules.findIndex(r => r.rule === 'Default');
    if (defaultStyleIndex === -1) {
      return;
    }
    this.selectedRuleIndex = defaultStyleIndex;
    this.onLabelListChange(defaultStyleIndex);
  }

  onLabelListChange(value) {
    if (!this.geomStyle.labelRules[value]) {
      return;
    }
    this.geomStyle.fillColor = this.geomStyle.labelRules[value].fillColor;
    this.geomStyle.lineColor = this.geomStyle.labelRules[value].lineColor;
    this.geomStyle.pointColor = this.geomStyle.labelRules[value].pointColor;
    this.geomStyle.pointBgColor = this.geomStyle.labelRules[value].pointBgColor;
    this.geomStyle.fillPatternColor = this.geomStyle.labelRules[value].fillPatternColor;
    this.geomStyle.linePatternColor = this.geomStyle.labelRules[value].linePatternColor;
    this.geomStyle.labelSize = this.geomStyle.labelRules[value].labelSize;
    this.geomStyle.labelColor = this.geomStyle.labelRules[value].labelColor;
    this.geomStyle.labelFont = this.geomStyle.labelRules[value].labelFont;
    this.geomStyle.labelEnabled = this.geomStyle.labelRules[value].labelEnabled;
    this.geomStyle.lineWidth = this.geomStyle.labelRules[value].lineWidth;
    this.geomStyle.lineStyle = this.geomStyle.labelRules[value].lineStyle;
    this.geomStyle.objLabel = this.geomStyle.labelRules[value].objLabel;
    this.geomStyle.rule = this.geomStyle.labelRules[value].rule;
    if (this.geomStyle.rule.includes('.') && this.geomStyle.rule.split('.')[1].includes('=')) {
      this.ruleKey = this.geomStyle.rule.split('.')[1].split('=')[0];
    } else {
      this.ruleKey = this.geomStyle.rule.split('.')[1];
    }
    this.ruleValue = this.geomStyle.rule.split('=')[1];
    this.geomStyle.pointIcon = this.geomStyle.labelRules[value].pointIcon;
    this.geomStyle.pointSize = this.geomStyle.labelRules[value].pointSize;
    this.geomStyle.pointForm = this.geomStyle.labelRules[value].pointForm;
    this.geomStyle.pointRadius = this.geomStyle.labelRules[value].pointRadius;
    this.geomStyle.pointRotation = this.geomStyle.labelRules[value].pointRotation;
    this.geomStyle.pointGradient = this.geomStyle.labelRules[value].pointGradient;
    this.geomStyle.fillPattern = this.geomStyle.labelRules[value].fillPattern;
    this.geomStyle.strokePattern = this.geomStyle.labelRules[value].strokePattern;
  }

  onRuleGen() {
    if (!this.ruleKey) {
      this.toastr.error('GENERATE_RULEKEY_ERROR');
      return;
    }
    if (!this.classifier) {
      return;
    }
    this.confirmDialogService.showConfirmationDialog(this.translateService.instant('GENERATE_WARNING')).then((goGenerate: boolean) => {
      if (goGenerate) {
        this.classifiersService.generateRandomStyle(this.classifier.id, this.ruleKey).then(res => {
          // Server returns JSON string with capital first letter keys ('FillColor'). Need to convert those to lower case ('fillColor').
          // Iterating through an array of Label Rule objects and lowercasing their keys before adding to our geomStyle.
          const newRules = [];
          for (const obj of JSON.parse(res)['LabelRules']) {
            newRules.push(_.transform(obj, function(result, val, key) {
              result[key.charAt(0).toLowerCase() + key.slice(1)] = val;
            }));
          }
          // Adding newly generated Label Rules to our geomStyle.
          this.geomStyle.labelRules = newRules;
          // Adding missing Default Label Rule.
          const defaultStyle = new GeomStyle;
          // No need for labelRules in Default rule (LabelRules are only for main GeomStyle).
          delete defaultStyle.labelRules;
          defaultStyle.rule = 'Default';
          this.geomStyle.labelRules.push(defaultStyle);
          // Auto select Default rule after retrieving new Label Rules.
          this.selectedRuleIndex = this.geomStyle.labelRules.findIndex(r => r.rule === 'Default');
          this.onLabelListChange(this.selectedRuleIndex);
        });
      }
    });
  }

  scrollToTarget() {
    if(this.selectedIcon){
      setTimeout(() => {
        this.selectedIcon.nativeElement.scrollIntoView({ block: 'center' });
      }, 200);
    }
  }
}

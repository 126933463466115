import { Directive, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MapService } from '@pgis/core/services/map/map.service';

/**
* Allows the aside to be toggled via click.
*/
@Directive({
  selector: '[appAsideMenuToggler]',
})
export class AsideToggleDirective implements OnInit, OnDestroy {
  constructor(private mapService: MapService) {}

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    this.registerMapRefresh();
    document.querySelector('body').classList.toggle('aside-menu-hidden');
  }

  ngOnInit(): void {
    document.querySelector('body').classList.remove('aside-menu-hidden');
    if (screen.width < 992) {
      document.querySelector('body').classList.add('aside-menu-hidden');
    } else {
      document.querySelector('body').classList.add('aside-menu-show');
    }
    this.registerMapRefresh();
  }

  ngOnDestroy(): void {
    this.registerMapRefresh();
    this.mapService.deselectElement();
    document.querySelector('body').classList.add('aside-menu-hidden');
  }

  private registerMapRefresh(): void {
    const asideMenuElement: Element = document.querySelector('.aside-menu');
    if (!asideMenuElement) {
      return;
    }

    asideMenuElement.addEventListener('transitionend', () => {
      this.mapService.reload();
    });
  }
}

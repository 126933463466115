import { Component, DoCheck, Input, IterableDiffers } from '@angular/core';
import { QueryBuilderConfig, QueryBuilderClassNames, RuleSet, Rule } from 'angular2-query-builder';

import { LayerField } from '@pgis/shared/models/layer-field.model';

@Component({
  selector: 'pgis-layer-filter',
  templateUrl: './layer-filter.component.html',
  styleUrls: ['./layer-filter.component.scss']
})
export class LayerFilterComponent implements DoCheck {

  @Input()
  layerFields: LayerField[];

  @Input()
  ruleset: RuleSet;

  classNames: QueryBuilderClassNames = {
    removeIcon: 'fa fa-minus',
    addIcon: 'fa fa-plus',
    arrowIcon: 'fa fa-chevron-right px-2',
    button: 'btn',
    buttonGroup: 'btn-group',
    rightAlign: 'order-12 ml-auto',
    switchRow: 'd-flex px-2',
    switchGroup: 'd-flex align-items-center',
    switchRadio: 'custom-control-input',
    switchLabel: 'custom-control-label',
    switchControl: 'custom-control custom-radio custom-control-inline',
    row: 'row p-2 m-1',
    rule: 'border',
    ruleSet: 'border',
    invalidRuleSet: 'alert alert-danger',
    emptyWarning: 'text-danger mx-auto',
    operatorControl: 'form-control',
    operatorControlSize: 'col-auto col-auto-sm pr-0',
    fieldControl: 'form-control',
    fieldControlSize: 'col-auto pr-0',
    entityControl: 'form-control',
    entityControlSize: 'col-auto pr-0',
    inputControl: 'form-control',
    inputControlSize: 'col-auto'
  };

  config: QueryBuilderConfig;

  iterableDiffer: any;

  constructor(private _iterableDiffers: IterableDiffers) {
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
  }

  initQueryBuilder() {
    if (!this.layerFields) {
      return;
    }
    this.config = {
      fields: {}
    };

    const fillDefaultRuleset: boolean = !this.ruleset;

    this.layerFields.forEach(lf => {
      this.config.fields[lf.name] = {
        name: lf.name,
        type: lf.type === 'text' ? 'string' : lf.type,
        operators: ['=', '!=']
      };
    });

    if (!this.ruleset.rules) {
      this.ruleset.rules = [];
      return;
    }
    // if QueryBuilder ruleset contains definition including field that was removed from layerFields
    this.ruleset.rules = this.ruleset.rules.filter((r: Rule) => this.layerFields.some(lf => lf.name === r.field));
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.layerFields);
    if (!changes) {
      return;
    }

    this.layerFields = changes.collection;
    this.initQueryBuilder();
  }
}

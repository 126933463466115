import { Component, OnInit } from '@angular/core';
import { HelpService } from './help.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'pgis-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit {

  helpFiles: string[];

  constructor(private helpService: HelpService,
    private activeModal: NgbActiveModal) { }

  async ngOnInit(): Promise<void> {
    this.helpFiles = await this.helpService.getFiles();
  }

  async downloadFile(fileName: string): Promise<void> {
    await this.helpService.downloadFile(fileName);
  }

  close(): void {
    this.activeModal.dismiss();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { ToastModule, ToastOptions } from 'ng6-toastr/ng2-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { OrderModule } from 'ngx-order-pipe';
import { MyDatePickerModule } from 'mydatepicker';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectizeModule } from 'ng-selectize';
import { QueryBuilderModule } from 'angular2-query-builder';

import { GroupByPipe, FilterPipe, KeysPipe, LegendGroupPipe } from './pipes';
import { ConfirmDialogComponent, DrawObjectModalComponent, InfoDialogComponent, MultiFileSelectorComponent, ObjectCommentsComponent } from './components';
import { PrintModalComponent } from '@pgis/views/dashboard/print-modal/print-modal.component';
import { GeometryFileModalComponent } from '@pgis/views/dashboard/geometry-file-modal';
import { InputTrimDirective } from './directives';

export class CustomOptions extends ToastOptions {
  showCloseButton = true;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    ToastModule.forRoot(),
    TranslateModule,
    OrderModule,
    MyDatePickerModule,
    NgSelectizeModule,
    DataTablesModule,
    QueryBuilderModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ToastModule,
    TranslateModule,
    OrderModule,
    MyDatePickerModule,
    DataTablesModule,
    LocalizeRouterModule,
    QueryBuilderModule,

    GroupByPipe,
    FilterPipe,
    KeysPipe,
    LegendGroupPipe,
    ConfirmDialogComponent,
    DrawObjectModalComponent,
    InputTrimDirective,
    NgSelectizeModule,
    InfoDialogComponent,
    MultiFileSelectorComponent,
    ObjectCommentsComponent
  ],
  declarations: [
    GroupByPipe,
    FilterPipe,
    KeysPipe,
    LegendGroupPipe,
    ConfirmDialogComponent,
    DrawObjectModalComponent,
    InputTrimDirective,
    InfoDialogComponent,
    PrintModalComponent,
    GeometryFileModalComponent,
    MultiFileSelectorComponent,
    ObjectCommentsComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    DrawObjectModalComponent,
    InfoDialogComponent,
    PrintModalComponent,
    GeometryFileModalComponent
  ],
  providers: [
    { provide: ToastOptions, useClass: CustomOptions }]
})
export class SharedModule {
}

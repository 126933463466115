import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';

import { ClassifiersService } from '@pgis/core/services/classifiers.service';
import { LocalizedToastrService } from '@pgis/core/services/localized-toastr.service';
import { ValidationService } from '@pgis/core/services/validation.service';
import { Classifier, User, DrawObject, LayerField } from '../../models';

@Component({
    templateUrl: './draw-object-modal.component.html',
    styleUrls: ['./draw-object-modal.component.scss']
})
export class DrawObjectModalComponent implements OnInit {

    drawObject: DrawObject = new DrawObject();

    classifiers: Classifier[];
    childClassifiers: Classifier[] = [];
    selectedGroup = null;
    groupObjects: number[] = [];

    selectedClassifier: number;
    selectedChildClassifier: number;

    layerFields: LayerField[];
    featureData: {};

    isPublicUser: boolean;
    isEditMode: boolean;
    geometryFiles: FormData;

    constructor(private activeModal: NgbActiveModal,
        private classifiersService: ClassifiersService,
        private validationService: ValidationService,
        private toastr: LocalizedToastrService) {
    }

    ngOnInit() {
        this.featureData = this.drawObject.parsedData;
        if(this.drawObject.parsedData){
            this.featureData = this.drawObject.parsedData;
        }
        else{
            this.featureData = this.drawObject.data ? JSON.parse(this.drawObject.data) : null;
        }
        this.drawObject.oldClassId = this.drawObject.classId;
        this.selectedChildClassifier = this.drawObject.classId ? this.drawObject.classId : 0;
        const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
        this.isPublicUser = !currentUser || !currentUser.token;

        this.classifiersService.getChildClassifiersWithChildren(1).then(data => {
            this.classifiers = data;
            if (this.drawObject.objectId) { //edit mode
                this.isEditMode = true;
                this.getChildClassifiers(this.drawObject.objectId);
            } else {
                const c = this.classifiers[0];
                this.getChildClassifiers(c.id);
                this.drawObject.objectId = c.id;
                this.drawObject.classId = c.childClassifiers[0].id;
            }
            this.selectedClassifier = this.drawObject.objectId || 0;
            this.selectedChildClassifier = this.drawObject.classId || 0;

            this.parseLayerFields(this.selectedChildClassifier.toString());
            if (this.isPublicUser) {
                this.drawObject.isPublic = true;
            }
        });
    }

    getChildClassifiers(objectId: number) {
        if (!objectId) {
            return [];
        }

        const selectedBaseClassifier = this.classifiers.find((c: Classifier) => c.id === Number(objectId));
        if (!selectedBaseClassifier) {
            return [];
        }

        this.childClassifiers = selectedBaseClassifier.childClassifiers;
        this.selectedChildClassifier = 0;
    }

    parseLayerFields(childClassifierId: string) {
        const c = <Classifier>this.childClassifiers.find(cc => cc.id === Number(childClassifierId));
        if (!c) {
            return;
        }

        if (!this.drawObject.data) {
            this.drawObject.data = {};
        }
        this.layerFields = c.layerFields;
        if (!this.featureData) {
            this.featureData = {};
            for (let field of this.layerFields) {
                this.featureData[field.name] = "";
            }
        }
    }

    updateGeometryFiles(geometryFiles: FormData): void {
        this.geometryFiles = geometryFiles;
    }

    onSubmit(form: FormGroup) {
        if (!form.valid) {
            this.validationService.markAllDirty((<any>form).form);
            return;
        }

        for (let field of this.layerFields) {
            this.featureData[field.name] = form.value[field.name];
        }
        this.drawObject.data = this.featureData;

        if (this.isEditMode) {
            this.activeModal.close(this.drawObject);
        } else {
            this.activeModal.close({
                drawObject: this.drawObject,
                geometryFiles: this.geometryFiles
            });
        }
    }

    closeModal() {
        this.activeModal.dismiss('Modal closed');
    }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoaderService } from '@pgis/core/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.pengindRequests++;
    this.loaderService.display(true);

    return next.handle(req).pipe(tap(event => {
        if (event instanceof HttpResponse) {
          if (--this.loaderService.pengindRequests === 0) {
            this.loaderService.display(false);
          }
        }
      },
      err => {
        if (err instanceof HttpErrorResponse) {
          if (--this.loaderService.pengindRequests <= 0) {
            this.loaderService.pengindRequests = 0;
            this.loaderService.display(false);
          }
        }
      }));
  }
}

import FontSymbol from 'ol-ext/style/FontSymbol';
import FillPattern from 'ol-ext/style/FillPattern';
import StrokePattern from 'ol-ext/style/StrokePattern';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import Stroke from 'ol/style/Stroke';
import olFeature from 'ol/Feature';
import olDraw from 'ol/interaction/Draw';
import GeoJSON from 'ol/format/GeoJSON';

import { ObjectFilter } from '@pgis/shared/models/object-filter.model';
import { Feature } from '@pgis/shared/models/feature.model';


export abstract class LayerSource {

  protected singleLayerToLoad: number | null;
  visibleClassifiers: number[];
   
  abstract type: string;
  abstract source: VectorSource;
  abstract loaded = {};
  abstract objectFilter: ObjectFilter;

  abstract getDrawIntersection(): olDraw;
  abstract getLayer(): VectorLayer;
  abstract loadGeometries(extent: number[], zoom: number, classifiersToLoad: number[]): Promise<void>;

  initSource(): void {
    this.source = new VectorSource({
      format: new GeoJSON(),
      loader: (extent, resolution, projection) => {
        const zeroZoomResolution = 156543.03390625;
        const zoom = Math.round((Math.log(zeroZoomResolution) * Math.LOG2E - Math.log(resolution) * Math.LOG2E));
        let classifiersToLoad = this.visibleClassifiers;
        if (this.singleLayerToLoad) {
          classifiersToLoad = [this.singleLayerToLoad];
        }

        if (!classifiersToLoad || classifiersToLoad.length === 0) {
          return;
        }

        this.loadGeometries(extent, zoom, classifiersToLoad).then(() => {
          if (this.singleLayerToLoad
            && !this.visibleClassifiers.includes(this.singleLayerToLoad)) {
            this.visibleClassifiers.push(this.singleLayerToLoad);
          }
          this.singleLayerToLoad = null;
        });
      },
      strategy: (extent: number[], resolution: number): number[][] => {
        return [this.expandExtent(extent)];
      }
    });
  }

  clear(): void {
    this.source.clear(false);
    this.loaded = {};
  }

  removeFeature(featureId: number): boolean {
    if (this.source) {
    const feature = this.source.getFeatures().find(f => f.values_.id === featureId);
    if (!feature) {
      return false;
    }

    this.source.removeFeature(feature);
    return true;
    }
    return false;
  }

  loadSingleLayer(layerId: number, extent: number[], resolution: number) {
    const index = this.visibleClassifiers.indexOf(layerId);
    if (index > -1) {
      return;
    }
    this.singleLayerToLoad = layerId;
    this.source.loader_(this.expandExtent(extent), resolution);
  }

  removeFeatures(layerId: number): void {
    if (this.visibleClassifiers) {
      const index = this.visibleClassifiers.indexOf(layerId);
      if (index === -1) {
        return;
      }

      this.source.forEachFeature((f: olFeature) => {
        if (f.values_.classId !== layerId) {
          return;
        }
        this.source.removeFeature(f);
        delete this.loaded[f.id_];
      });
    }
  }

  protected addFeatureToSource(oFeature: olFeature, geomFeature: Feature) {
    oFeature.setId(geomFeature.id);
    this.source.addFeature(oFeature);
    const objData = JSON.parse(JSON.stringify(geomFeature));
    delete objData.geom;
    oFeature.setProperties(objData);
    this.setFeatureStyle(oFeature, objData);
    this.loaded[objData.id] = true;
  }

  private expandExtent(extent: number[]): number[] {
    const extentExpansionCoef = 0.1;
    const xDiff = extent[2] - extent[0];
    const yDiff = extent[3] - extent[1];

    extent[0] -= xDiff * extentExpansionCoef;
    extent[1] -= yDiff * extentExpansionCoef;
    extent[2] += xDiff * extentExpansionCoef;
    extent[3] += yDiff * extentExpansionCoef;

    return extent;
  }

  private createStyle(feature: olFeature, objData: any, geomStyle: any) {
    let customStyle = null;
    if (feature.values_.geometry.constructor.name === 'Point') {
      customStyle = new Style({
        text: new Text({
          text: geomStyle.pointIcon.toString(),
          fill: new Fill({ color: geomStyle.pointColor }),
          font: 'normal ' + geomStyle.pointSize.toString() + 'px FontAwesome'
        }),
        image: new FontSymbol({
          form: geomStyle.pointForm.toString(),
          glyph: '',
          radius: +geomStyle.pointRadius,
          rotation: +geomStyle.pointRotation,
          gradient: geomStyle.pointGradient,
          opacity: this.returnAlphaFromRgba(geomStyle.pointBgColor),
          fill: new Fill({ color: geomStyle.pointBgColor }),
        }),
        zIndex: objData.zIndex
      });
    } else {
      customStyle = new Style({
        fill: new Fill({
          color: geomStyle.fillColor
        }),
        stroke: new Stroke({
          color: geomStyle.lineColor,
          width: geomStyle.lineWidth,
        }),
        zIndex: objData.zIndex
      });
      if (geomStyle.lineStyle === 'dashed') {
        customStyle.stroke_.lineDash_ = [8, 8];
      }
      //ol-ext polygon Fill Pattern
      if (geomStyle.fillPattern) {
        customStyle.fill_ = new FillPattern({
          pattern: geomStyle.fillPattern.toString(),
          color: geomStyle.fillPatternColor,
          fill: new Fill({ color: geomStyle.fillColor })
        });
      }
      if (geomStyle.strokePattern) {
        customStyle.stroke_ = new StrokePattern({
          pattern: geomStyle.strokePattern.toString(),
          color: geomStyle.linePatternColor,
          width: geomStyle.lineWidth,
          fill: new Fill({ color: geomStyle.lineColor })
        });
      }
    }
    if (geomStyle.labelEnabled && geomStyle.objLabel) {
      customStyle.text_ = new Text({
        text: objData.objectMapLabel,
        fill: new Fill({ color: geomStyle.labelColor }),
        font: 'bold ' + geomStyle.labelSize + 'px ' + geomStyle.labelFont
      });
      if (feature.values_.geometry.constructor.name === 'LineString') {
        customStyle.text_.placement_ = 'line';
      }
    }
    return customStyle;
  }

  private setFeatureStyle(feature: olFeature, objData: Feature) {
    const geomStyle = (objData.geomStyle);
    if (!geomStyle) {
      return;
    }

    const customStyle = this.createStyle(feature, objData, geomStyle);
    feature.setStyle(customStyle);
  }

  private hexToRgba(hex, opacity) {
    if (!hex) {
      return;
    }
    if (!opacity) {
      opacity = 1;
    }
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    return result;
  }

  private returnAlphaFromRgba(color) {
    if (!color || color.substring(0, 4) !== 'rgba') {
      return '1';
    }
    const temp = color.split(',');
    const alpha = temp[temp.length - 1].slice(0, -1);
    return alpha;
  }
}

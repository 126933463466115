import { Injectable } from '@angular/core';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import * as olObservable from 'ol/Observable';
import olPolygon from 'ol/geom/Polygon';
import olLineString from 'ol/geom/LineString';
import olDraw from 'ol/interaction/Draw';
import * as olSphere from 'ol/sphere';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle';

@Injectable({
  providedIn: 'root'
})
export class MapMeassureService {

  map: Map;
  sketch: Feature;
  helpTooltipElement: HTMLElement;
  helpTooltip: Overlay;
  measureTooltipElement: HTMLElement;
  measureTooltip: Overlay;
  continuePolygonMsg: string = 'Click to continue drawing the polygon';
  continueLineMsg: string = 'Click to continue drawing the line';
  measureSource: VectorSource;
  measureLayer: VectorLayer;
  pointermoveEvent: {};
  mouseoutEvent: {};
  drawStartEvent: {};
  drawEndEvent: {};
  drawInteraction: olDraw;

  constructor() {
    this.measureSource = new VectorSource();
  }

  /**
*
* @param map Sets ol.Map
* @param toolType Sets type of meassure too values: line,area
*/
  enable(map: Map, toolType: string) {
    this.map = map;
    this.setUp();
    this.setMessureTool(toolType);
  }

  disable() {
    if (this.map) {
      this.map.removeLayer(this.measureLayer);
      olObservable.unByKey(this.pointermoveEvent);
      this.map.getViewport().removeEventListener('mousemove', this.mouseoutEvent);

      olObservable.unByKey(this.mouseoutEvent);
      this.measureSource.clear();
      if (this.measureTooltipElement) {
        this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
        this.measureTooltipElement = null;
        this.map.removeOverlay(this.measureTooltip);
        this.measureTooltip = null;

        const staticTooltips = document.getElementsByClassName('tooltip-static');

        while (staticTooltips.item(0)) {
          const item = staticTooltips.item(0);
          item.parentNode.removeChild(item);
        }
      }

      if (this.helpTooltipElement) {

        this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
        this.helpTooltipElement = null;
        this.map.removeOverlay(this.helpTooltipElement);
        this.helpTooltipElement = null;
        this.helpTooltip = null;
      }
    }

    if (this.drawInteraction) {
      olObservable.unByKey(this.drawStartEvent);
      this.drawInteraction.removeEventListener(this.drawStartEvent);
      olObservable.unByKey(this.drawEndEvent);
      this.drawInteraction.removeEventListener(this.drawEndEvent);
      this.map.removeInteraction(this.drawInteraction);
    }
  }

  private setUp() {
    this.measureLayer = new VectorLayer({
      source: this.measureSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33'
          })
        })
      })
    });
    this.map.addLayer(this.measureLayer);
  }

  private pointerMoveHandler(evt) {
    if (evt.dragging) {
      return;
    }
    let helpMsg = 'Click to start drawing';
    if (this.sketch) {
      const geom = (this.sketch.getGeometry());
      if (geom instanceof olPolygon) {
        helpMsg = this.continuePolygonMsg;
      } else if (geom instanceof olLineString) {
        helpMsg = this.continueLineMsg;
      }
    }
    this.helpTooltipElement.innerHTML = helpMsg;
    this.helpTooltip.setPosition(evt.coordinate);
    this.helpTooltipElement.classList.remove('hidden');
  }

  private createHelpTooltip() {
    if (this.helpTooltipElement) {
      this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
      this.helpTooltipElement.remove();
      this.map.removeOverlay(this.helpTooltipElement);
    }
    this.helpTooltipElement = document.createElement('div');
    this.helpTooltipElement.className = 'tooltip hidden';
    this.helpTooltip = new Overlay({
      element: this.helpTooltipElement,
      offset: [-20, -60],
      positioning: 'center-left'
    });
    this.map.addOverlay(this.helpTooltip);
  }

  private createMeasureTooltip() {
    if (this.measureTooltipElement) {
      // this.measureTooltipElement.remove();
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
      this.map.removeOverlay(this.measureTooltip);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'tooltip tooltip-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [-20, -60],
      positioning: 'bottom-center'
    });
    this.map.addOverlay(this.measureTooltip);
  }

  formatLength(line) {
    const length = olSphere.getLength(line);
    let output;
    if (length > 100) {
      output = (Math.round(length / 1000 * 100) / 100) +
        ' ' + 'km';
    } else {
      output = (Math.round(length * 100) / 100) +
        ' ' + 'm';
    }
    return output;
  }

  formatArea(polygon) {
    const area = olSphere.getArea(polygon);
    let output;
    if (area > 10000) {
      output = Math.round(area / 1000000 * 100) +
        ' ' + 'ha';
    } else {
      let valueHa = (Math.round(area * 100) / 100) * 0.0001;
      output = Number(valueHa.toFixed(2)) +
        ' ' + 'ha';
    }
    return output;
  }

  /**
   *
   * @param toolType Sets type of meassure too values: line,area
   */
  private setMessureTool(toolType: string) {
    this.pointermoveEvent = this.map.on('pointermove', () => this.pointerMoveHandler);
    //    this.mouseoutEvent =this.map.getViewport().addEventListener('mouseout', () => {
    //      this.helpTooltipElement.classList.add('hidden');
    //    });

    const type = (toolType === 'area' ? 'Polygon' : 'LineString');
    this.drawInteraction = new olDraw({
      source: this.measureSource,
      type: type
    });
    this.map.addInteraction(this.drawInteraction);
    this.createMeasureTooltip();
    this.createHelpTooltip();

    let listener;
    this.drawStartEvent = this.drawInteraction.on('drawstart', (drawStartEvt) => {
      this.sketch = drawStartEvt.feature;
      let tooltipCoord = drawStartEvt.coordinate;
      listener = this.sketch.getGeometry().on('change', (evt) => {
        const geom = evt.target;
        let output = '';
        if (geom instanceof olPolygon) {
          output = this.formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof olLineString) {
          output = this.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        this.measureTooltipElement.innerHTML = output;
        this.measureTooltip.setPosition(tooltipCoord);
      });
    });

    this.drawEndEvent = this.drawInteraction.on('drawend', () => {
      this.measureTooltipElement.className = 'tooltip tooltip-static';
      this.measureTooltip.setOffset([-20, -60]);
      // unset sketch
      this.sketch = null;
      // unset tooltip so that a new one can be created
      this.measureTooltipElement = null;
      this.createMeasureTooltip();
      olObservable.unByKey(listener);

    });

    return this.drawInteraction;

  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Classifier } from '@pgis/shared/models';
import { ObjectFilter } from '@pgis/shared/models/object-filter.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClassifiersService {

  private _classifierToggled = new Subject<number>();
  public classifierToggled = this._classifierToggled.asObservable();

  constructor(public http: HttpClient) {
  }

  getBaseClassifiers(): Promise<Classifier[]> {
    return this.http.get<Classifier[]>('/api/v1/classifiers').toPromise();
  }

  getClassifier(classifierId): Promise<Classifier> {
    return this.http.get<Classifier>('/api/v1/classifiers/single/' + classifierId).toPromise();
  }

  getChildClassifiers(baseId: number): Promise<Classifier[]> {
    return this.http.get<Classifier[]>('/api/v1/classifiers/' + baseId).toPromise();
  }

  getChildClassifiersWithChildren(baseId: number): Promise<Classifier[]> {
    return this.http.get<Classifier[]>(`/api/v1/classifiers/${baseId}/include-children`).toPromise();
  }

  getAllChildClassifiersWithChildren(baseId: number): Promise<Classifier[]> {
    return this.http.get<Classifier[]>(`/api/v1/classifiers/${baseId}/include-children/admin`).toPromise();
  }

  getMapLayerClassifiers(objectFilter?: ObjectFilter): Promise<Classifier[]> {
    let params = new HttpParams();
    if (objectFilter) {

      Object.keys(objectFilter).forEach(function (key) {
        params = params.append(key, objectFilter[key]);
      });
    }
    return this.http.get<Classifier[]>('/api/v1/classifiers/layers', { params: params }).toPromise();
  }

  updateClassifier(baseId: number, item: Classifier): Promise<any> {
    return this.http.put<Classifier>('/api/v1/classifiers/' + baseId, item).toPromise();
  }

  deleteClassifier(baseId: number, id: number): Promise<any> {
    return this.http.delete(`/api/v1/classifiers/${baseId}/${id}`).toPromise();
  }

  deleteClassifierGroup(baseId: number): Promise<any> {
    return this.http.delete(`/api/v1/classifiers/${baseId}`).toPromise();
  }

  createClassifier(baseId: number, item: Classifier): Promise<any> {
    return this.http.post<Classifier>('/api/v1/classifiers/' + baseId, item).toPromise();
  }

  duplicateClassifier(baseId: number, id: number, classifier: Classifier): Promise<any> {
    delete classifier.id;
    return this.http.post<Classifier>(`/api/v1/classifiers/${baseId}/${id}/duplicate`, classifier).toPromise();
  }

  removeLayerData(baseId: number, id: number): Promise<void> {
    return this.http.post<void>(`/api/v1/classifiers/${baseId}/${id}/remove-data`, null).toPromise();
  }

  emitClassifierToogle(layerId: number): void {
    this._classifierToggled.next(layerId);
  }

  generateRandomStyle(classifierId: number, ruleKey: string): Promise<string> {
    return this.http.post<string>(`/api/v1/classifiers/${classifierId}/generate-style/${ruleKey}`, null).toPromise();
  }
}

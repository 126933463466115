import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  createUser(data): Promise<any> {
    return this.http.post<any>('api/v1/user/', data).toPromise();
  }

  updateUserPassword(userId: number, passwordData: any) {
    return this.http.put<any>('api/v1/user/' + userId, passwordData).toPromise();
  }

  remindUserPassword(token: string, passwordData: any) {
    return this.http.put<any>('api/v1/user/new/' + token, passwordData).toPromise();
  }

  getUser(userId: number): Promise<any> {
    return this.http.get<any>('api/v1/user/' + userId).toPromise();
  }

  disableUser(userId: number, userStatus: boolean): Promise<any> {
    return this.http.post<any>('api/v1/user/' + userId + '/status/' + userStatus, null).toPromise();
  }
}

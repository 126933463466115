import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {

  confirmationMsg: string;

  constructor(private activeModal: NgbActiveModal) {
  }

  onYes() {
    this.activeModal.close(true);
  }

  onNo() {
    this.activeModal.dismiss(false);
  }
}

export enum MapActions {
    None = 'None',
    DrawPoint = 'DrawPoint',
    DrawLine = 'DrawLine',
    DrawPolygon = 'DrawPolygon',
    SelectObject = 'SelectObject',
    DragObject = 'DragObject',
    Print = 'Print',
    MeassureDistance = 'MeassureDistance',
    MeassureArea = 'MeassureArea',
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { MapService } from '@pgis/core/services/map/map.service';
import { filter } from 'rxjs/operators';
import { User } from '@pgis/shared/models/user.model';

@Component({
  selector: 'app-aside',
  templateUrl: './app-aside.component.html',
  styleUrls: ['./app-aside.component.scss']
})
export class AppAsideComponent implements OnInit, OnDestroy {

  mapObjectChangeSubstciption: Subscription;
  onReauthenticationSubscription: Subscription;
  selectedObject: any;
  isPublicUser: boolean;

  constructor(private mapService: MapService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    this.isPublicUser = !currentUser || !currentUser.token;
    const sidebarElement: Element = document.querySelector('.sidebar');
    if (!sidebarElement) {
      return;
    }
    sidebarElement.addEventListener('transitionend', () => {
      this.mapService.reload();
    });
    document.querySelector('body').classList.add('sidebar-minimized');
    document.querySelector('body').classList.add('brand-minimized');
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      document.querySelector('body').classList.remove('sidebar-minimized');
      document.querySelector('body').classList.remove('brand-minimized');
    });
    this.mapObjectChangeSubstciption = this.mapService.selectedObject.subscribe(selectedObject => {
      this.selectedObject = selectedObject;
      if (!selectedObject) {
        return;
      }
    });
  }

  deselectGeometryId() {
    this.selectedObject = null;
  }

  ngOnDestroy(): void {
    this.mapObjectChangeSubstciption.unsubscribe();
  }
}

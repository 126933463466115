import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './geometry-file-modal.component.html',
  styleUrls: ['./geometry-file-modal.component.scss']
})

export class GeometryFileModalComponent {
  @Input() geometryId;

  constructor(public activeModal: NgbActiveModal) {
  }

  submitFileUpload(geometryFiles: FormData) {
    geometryFiles.append('geometryId', this.geometryId.toString());
    this.activeModal.close(geometryFiles);
  }
}
